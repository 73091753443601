.modal.show {
  display: block;
}

#enh-modal .slider-single img{
  border-radius: 2.8rem !important;
}


/* 3d slider css */

#enh-modal .react-3d-carousel {
  height: calc(100vh - 100px) !important;
  display: flex;
  align-items: center;
}

#enh-modal .react-3d-carousel .slider-container {
  height: 100%;
  display: flex;
  align-items: center;
}


#enh-modal .modal-dialog.modal-dialog-centered {
  max-width: 70%;
  margin: auto;
}

/* #enh-modal .react-3d-carousel .slider-container .slider-content .slider-single {
  height: 100%;
} */

#enh-modal .slider-single img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
#enh-modal .modal-content{
  border: none;
}
#enh-modal .react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
  height: 400px;
}
#enh-modal button.btn-close {
  z-index: 9999999;
}
.pagination-sec ul.pagination-list li a.active {
  background: rgba(255, 255, 255, 1);
}


.enh_qty {
  max-width: 8rem;
  border-radius: 4rem;
  box-shadow: none;
  border: 1px solid gray;
  height: 4rem !important;
  font-family: proxima-nova, sans-serif;
  font-size: 2rem;
  text-align: center;
}
.qty-wrapper {
  width: 100px;
  align-items: center;
  justify-content: center;
}
.qty-wrapper label {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  padding-right: 1rem;
}
.enh_qty:focus-visible {
  outline: none;
}


.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left, .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right {
  height: 30px;
  width: 30px;
  padding: 0;
  bottom: -40px;
  margin: 0;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left {
  left: 42%;
  right: auto;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right {
  right: 42%;
  left: auto;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left div, .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right div {
  margin-top: 0;
  position: unset;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transform: none;
  border: none;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right div {
  background-image: url("../../assets/images/awesome-play-circle.svg");
  background-size: contain;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left div i, .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right div i {
  display: none;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left div {
  background-image: url("../../assets/images/awesome-play-circle.svg");
  background-size: contain;
  transform: rotate(180deg);
}


.pdf-info-list h5 {
  text-align: left !important;
  margin-bottom: 10px;
}
.pdf-info-list  img {
  padding-right: 6px;
}

.info-popup-overlay .modal-dialog {
  max-width: 400px!important;
}
.info-popup-overlay .modal-dialog .modal-body {
  align-items: normal!important;
  justify-content: normal!important;
  padding: 30px!important;
  min-height: auto !important;
  max-height: 550px;
  overflow-y: scroll;
  margin-right: 40px !important;
  margin-bottom: 50px;
}
.showModal{
  opacity:1!important;
}
.mdlcls{
  z-index: 99;
}

@media only screen and (max-width:1440px) {
.info-popup-overlay .modal-dialog .modal-body { 
  padding:20px!important; 
  max-height: 450px; 
  margin-right: 30px !important;
  margin-bottom: 30px;
}
.pdf_document_main.modal_project_pdf ul.pdf_schroll_slider li a img {
  width: 94px !important;
}
}

@media only screen and (max-width:991px) {
  .info-popup-overlay .modal-dialog .modal-body {
    padding: 20px!important;
    max-height: 450px;
    margin-right: 30px !important;
    margin-bottom: 30px;
    margin-left: 8px;
}
  .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left {
    left: 40%;
}
.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right {
  right: 40%;
}
}
@media only screen and (max-width:767px) {
  .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left {
    left: 38%;
}
.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right {
  right: 38%;
}
}

@media only screen and (max-width:575px) {
  #enh-modal .modal-dialog.modal-dialog-centered {
    max-width: 90%;
    margin: auto;
}
  .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-left {
    left: 32%;
}
.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-right {
  right: 32%;
}
}