/* proxiva nova font */
@import url("https://use.typekit.net/nzg4lfi.css"); 

 

html {
  /* 62.5% of 16px browser font size is 10px */
  font-size: 42.5%;
}

body {
  padding: 0;
  margin: 0;
  font-family: proxima-nova, sans-serif;
  color:#181617;
}

b,strong{
  font-weight: bold !important;
}
p,
th,
td,
a,
input,
.form-select,
textarea,
.btn {
  font-family: proxima-nova, sans-serif;
  font-size: 2.5rem;
  line-height: 3.7rem;
  color: #8a8a8a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: proxima-nova, sans-serif;
}

h1 {
  font-size: 4.7rem;
  line-height: 5.7rem;
}

h2 {
  font-size: 3.7rem;
  line-height: 4.5rem;
}

h3 {
  font-size: 3rem;
  line-height: 3.7rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 2.8rem;
}

h5 {
  font-size: 2.2rem;
  line-height: 2.6rem;
}

h6 {
  font-size: 2rem;
  line-height: 2.4rem;
}

.font-light {
  font-weight: 100;
}

.heading-test {
  color: red;
}

.navbar-nav li a img {
  width: auto;
  margin: auto;
  max-width: 100%;
  height: 36px;
}

a.navbar-brand {
  margin-right: 0;
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;
}

.side-bar .navbar {
  background-color: #181617 !important;
}

.navbar-expand-md .navbar-collapse {
  align-items: flex-start;
}

.navbar-nav li a {
  display: flex;
  text-align: left;
  margin-bottom: 38px;
  color: #fff;
}
.navbar-nav .nav-link.active {
  color: #34b2b0;
}

ul.navbar-nav {
  margin-top: 21px;
}
.navbar-nav li a span {
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: 4rem;
  line-height: 4.1rem;
  width: 100%;
  margin-left: 25px;
}
.navbar-toggler-icon {
  width: 3.5rem;
  height: 3.5rem;
}

/* typography css */
.main-heading {
  font-size: 4.7rem;
  line-height: 5.7rem;
  color: #181617;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-style: normal;
}

/* main-content section css start from here */
.main-content .container,
.footer-section .container {
  max-width: 90%;
}

.main-content {
  height: 100vh;
  display: flex;
  align-items: center;
}

.team-member h3 {
  font-size: 28px;
  line-height: 25px;
  color: #ffffff !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-top: 0;
}

.team-member h3 span {
  font-weight: 100 !important;
  font-size: 16px !important;
  font-style: italic;
  font-family: proxima-nova, sans-serif;
}

.member-profile-pic {
  background: #e9e9e9;
  border-radius: 100%;
  height: 21.2rem;
  width: 21.2rem;
  margin: auto;
}

img.profile-pic {
  border-radius: 100%; 
}
.bg_black_transparent_team {
  /* background: rgba(255,255,255,0.8); */
  -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background: #ffffff7a;
  padding: 10px;
  margin-top: 12px;
  min-height: 125px;
}


.team-member {
  margin-top: 4rem;
  max-width: 290px;
}

.team-page .footer-section .cta-btn .prev-btn {
  opacity: 0;
}

.popup-cta-btn a {
  border: none;
}

.team-member-contacts {
  list-style: none;
  padding-left: 0px;
  width: 100%;
  margin-bottom: 0px;
}

.team-member-contacts li img {
  margin-right: 3px;
  filter: brightness(0.2);
}

.disable-tm .team-member-contacts {
  opacity: 0.18;
}
ul.team-member-contacts li a {
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  text-decoration: none;
  display: block;
  word-wrap: break-word;
  line-height: 15px;
}
.team-member h4 {
  color: #fff;
  font-size: 15px;
}
.header_team_vs h1,
.header_team_vs p {
  color: #191816;
}

.header_team_vs.text-center {
  margin-top: -90px;
  -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background: #ffffff7a;
  padding: 10px;
}
/* your glass page css start from here */
.bg-image {
  position: absolute;
  z-index: -1;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
}

img.bg-img.img-fluid {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.estimate-content-section.collapsible-shutter {
  width: 18rem;
  height: 13rem;
  transition: width 0.3s, height 0.3s;
  overflow: hidden;
}

.pagination-sec.more_nav_icons {
  height: 35rem;
}

.estimate-content-section {
  transition: width 0.3s, height 0.3s;
  width: 100rem;
  height: 80rem;
}

.rgt-sec-cta a img {
  width: 100%;
}

.estimate-content-section.collapsible-shutter .shutter-sec p {
  display: block;
  margin-bottom: 0;
}

.estimate-content-section.collapsible-shutter .shutter-sec {
  background: linear-gradient(#f0eded, #ccc8c8);
  padding: 2.4rem;
  transition: 0.3s ease-in-out;
}

.shutter-sec p {
  font-size: 2.2rem !important;
}

.colapsible-icon img {
  transition: 0.3s ease-in-out;
  width: 3rem;
}

.estimate-content-section.collapsible-shutter .colapsible-icon img {
  transform: rotate(175deg);
  transition: 0.3s ease-in-out;
}

.extra-btn-sec {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

/* estimate sec css start from here */
/* .estimate-content-section {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2.8rem;
    opacity: 1;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 1);
} */

ul.estimate-heading-list {
  padding: 0;
  list-style: none;
}

ul.estimate-heading-list li:nth-child(odd) {
  background: transparent linear-gradient(270deg, #f0eded 0%, #ccc8c8 100%) 0%
    0% no-repeat padding-box;
}

ul.estimate-heading-list li {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.estimate-content-section h3 {
  font-size: 2.7rem;
  line-height: 3rem;
  font-family: proxima-nova, sans-serif;
  color: #181617;
  font-weight: 700;
}

.estimate-content-section p {
  color: #181617;
  font-size: 2rem;
  line-height: 2rem;
}

.optn-btn {
  background: transparent;
  border-radius: 2.5rem;
  color: #181617;
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: proxima-nova, sans-serif;
  padding: 0.5rem 1.5rem;
  min-width: 12rem;
  border: 2px solid #181617;
}

.optn-btn:hover,
.optn-btn.active {
  background: transparent;
  border: 2px solid #181617;
  color: #181617;
}

.estimate-content-section {
  border-radius: 2.8rem;
  padding-bottom: 4rem;
  position: relative;
  /* background-color: rgba(255, 255, 255, 0.8); */
      -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background: #ffffff7a;
}

ul.estimate-heading-list {
  padding: 0;
  list-style: none;
}

ul.estimate-heading-list li:nth-child(even) {
  background: transparent linear-gradient(90deg, #f0eded 0%, #ccc8c8 100%) 0% 0%
    no-repeat padding-box;
}

ul.estimate-heading-list li {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 2.8rem 2.8rem 0 0;
}

.estimate-content-section h3 {
  font-size: 2.7rem;
  line-height: 3rem;
  font-family: proxima-nova, sans-serif;
  color: #181617;
  font-weight: 700;
}

.estimate-content-section p {
  color: #181617;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 100;
}
.estimate-content-section .check_disable_qty p {
  font-weight: 300;
}

.optn-btn {
  background: transparent;
  border-radius: 2.5rem;
  color: #181617;
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: proxima-nova, sans-serif;
  padding: 0.5rem 1.5rem;
  min-width: 12rem;
  border: 2px solid #181617;
}

.optn-btn:hover,
.optn-btn.active {
  background: transparent;
  border: 2px solid #181617;
  color: #181617;
}

.ecs-scrolling-description-sec {
  padding-left: 3rem;
  padding-right: 3rem;
  height: 50rem;
  overflow-y: auto;
  margin-right: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.ecs-scrolling-description-sec h5 {
  font-size: 2rem;
  color: #181617;
  line-height: 2.4rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-bottom: 23px;
}

.ecs-scrolling-description-sec h3 {
  font-size: 2.2rem;
  line-height: 2.5rem;
  font-weight: 400;
}

.main-wrap-sec {
  margin-bottom: 2rem;
}
.rgt-sec h3 {
  text-align: right;
  margin-bottom: 1rem;
}

.confirm_all_button {
  font-size: 2.2rem;
  line-height: 2.4rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 0.4rem;
  padding: 1rem 3rem;

  position: relative;
  background-color: #62bbbc !important;
  color: #fff;
  /* max-width: 25rem; */
  margin-left: auto;
  display: flex;
  justify-content: center;
  width: max-content;
  margin-top: -30px;
  cursor: pointer;
  z-index: 2;
}

.ecs-scrolling-description-sec a {
  background: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  color: #fff;
}

.handle_price_vs .select_handle_btn.diabled_seclet_btn {
  opacity: 0.8;
  background: #333 !important;
  color: #fff;
  border: 1px solid #333 !important;
  cursor: not-allowed;
}
.handle_price_vs .select_handle_btn.diabled_seclet_btn:hover {
  background: #333 !important;
  color: #fff;
  border: 1px solid #333 !important;
  cursor: not-allowed;
}

.rgt-sec-cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rgt-sec-cta a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
}

.add-btn {
  background-color: #dd8f41 !important;
  border-radius: 3rem !important;
  width: auto !important;
  height: 4rem !important;
  font-family: proxima-nova, sans-serif;
  font-size: 2rem;
  padding: 1rem 2rem !important;
  border: none;
  margin-left: 1rem;
  color: #fff;
}

.ecs-scrolling-description-sec ul {
  list-style: disc;
  padding-left: 0;
  margin-bottom: 12px;
}

.ecs-scrolling-description-sec ul li {
  font-size: 2rem;
  font-weight: 100;
  color: #181617;
  min-width: 87px !important;
  margin-right: 13px;
  position: relative;
}

.brand-list {
  margin: 4rem 0;
}
.pdf_document_main ul.pdf_schroll_slider li a b {
  position: absolute;
  left: 0;
  border: 0;
  font-weight: 300;
  opacity: 0;
  display: block;
}

.pdf_document_main ul.pdf_schroll_slider li:hover a b {
  opacity: 1;
}

.pdf_document_main ul.pdf_schroll_slider li a img {
  width: 77px !important;
}

.total-estimate {
  background: transparent linear-gradient(90deg, #dfdfdf 0%, #ccc8c8 100%) 0% 0%
    no-repeat padding-box;
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.price-count span {
  font-weight: 300;
}

.total-btn {
  width: auto !important;
  height: auto !important;
  border: none;
  border-radius: 1rem !important;
  font-size: 2rem;
  padding: 1rem 2rem !important;
}

.price-count h5 {
  margin-bottom: 0.5rem;
}

.shutter-sec {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 18rem;
  height: 13rem;
  overflow: hidden;
  border-radius: 2.8rem;
}
.estimate-content-section.project_vs_left.small_box_review
  .colapsible-icon
  img {
  transform: rotate(134deg);
}

.colapsible-icon {
  width: 9rem;
  height: 15rem;
  background: transparent linear-gradient(294deg, #f0eded 0%, #ccc8c8 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: normal;
  transform: rotate(45deg);
  padding-left: 1rem;
  position: absolute;
  right: -3rem;
  bottom: -6rem;
}

.estimate-content-section.colapsible {
  width: 18rem;
  height: 13rem;
}

.estimate-content-section.colapsible .colapsible-icon img {
  transform: rotate(175deg);
}

.estimate-content-section.colapsible .shutter-sec {
  background: transparent linear-gradient(270deg, #f0eded 0%, #ccc8c8 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shutter-sec p {
  display: none;
}

.estimate-content-section.colapsible .shutter-sec p {
  display: block;
  padding: 1.5rem;
}

.lft-sec {
  width: 70%;
}
.estimate-right-sec h3 {
  font-weight: 400;
}

.img-outer-cus {
  height: 327px;
}

.img-outer-cus img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* footer css start from here */
.footer-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 2;
}

.contact-no,
.cta-btn,
.popup-cta-btn,
.pagination-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 2.8rem;
  opacity: 1;
  backdrop-filter: blur(3rem);
  -webkit-backdrop-filter: blur(3rem);
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contact-no,
.cta-btn {
  justify-content: center;
}

.chatbot-icon {
  background-color: #fff;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-no,
.cta-btn {
  width: 50.3rem;
  height: 11.1rem;
}

.contact-no {
  width: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

.footer-cta-btn .btn:focus {
  box-shadow: none !important;
}

.main-content .container,
.main-content .container-fluid {
  padding-left: 4rem;
  padding-right: 4rem;
}

.footer-section .container {
  padding-left: 0;
  padding-right: 0;
}

.popup-cta-btn {
  width: 27rem;
  height: 9rem;
}

.cta-btn,
.print-info-btn {
  width: 30rem;
  height: 11rem;
}

.popup-cta-btn img {
  width: 5rem;
}

.cta-btn .next-btn {
  color: #fff;
}

.cta-btn .btn {
  font-size: 2.2rem;
  line-height: 3rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  border: none;
  border-radius: 1.8rem;
  padding: 2rem 1rem;
  text-transform: uppercase;
  position: relative;
  background-color: transparent;
  white-space: nowrap;
}

.button-lottie-ani > div {
  width: auto !important;
  height: 200px !important;
}
.confirm-all-btn-main {
  font-size: 2rem !important;
}

.gray-lottie-btn path {
  fill: #bebebe;
}

.cta-btn .btn.prev-btn {
  font-weight: 400;
  color: #181617;
}

.button-lottie-ani {
  position: relative;
}

.button-lottie-ani .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.contact-no h3 {
  letter-spacing: 0px;
  color: #181617;
}

.contact-no h3 {
  font-size: 3rem;
  line-height: 3.7rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0;
  color: #fff;
}

.contact-no h3 a {
  color: #181617;
  text-decoration: none;
  padding-left: 1rem;
  font-weight: 400;
}
a.btn.btn-primary.next-btn.bg-gray-btn.confirm-all-btn-main {
  background: transparent;
  border: 0;
}
.approved_button_right .btn {
  margin: 0;
  background: #62bbbc !important;
  border-radius: 0;
  width: 140px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 29px !important;
  font-weight: 500;
  color: #fff;
}

.approved_button_right .btn:hover {
  margin: 0;
  background: #181617 !important;
}
.outer_start_main a {
  display: block;
}

.outer_start_main a.button_start_glazing {
  margin: 30% auto;
  background: #62bbbc !important;
  border-radius: 0;
  max-width: 317px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px !important;
  font-weight: 100 !important;
  color: #fff;
  text-transform: capitalize !important;
}
.outer_start_main p {
  font-size: 21px;
  font-weight: 300;
  color:#fff;
}

.outer_start_main {
  text-align: center;
}
.popup-cta-btn a:active,
.popup-cta-btn a:focus,
.popup-cta-btn a:hover {
  border: transparent;
  background-color: transparent !important;
}
.qc-button .btn-edit.btn:hover,
.qc-button .btn-request.btn:hover {
  background-color: #62bbbc !important;
}

.pagination-sec {
  position: absolute;
  right: 5%;
  top: 30%;
  width: 8.5rem;
  height: auto;
  padding: 2rem 1rem;
}

.pagination-scrollable {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

ul.pagination-list {
  padding-left: 0;
  margin-bottom: 0;
}

.pagination-sec ul.pagination-list li {
  padding-bottom: 2rem;
}

.pagination-sec ul.pagination-list li a {
  background: rgba(255, 255, 255, 0.5);
  width: 4.6rem;
  height: 4.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #181617;
  font-size: 2rem;
  line-height: 2.4rem;
  border-radius: 50%;
  margin: auto;
  text-align: center;
}

.pagination-sec ul.pagination-list li a:hover {
  background: rgba(255, 255, 255, 1);
}

/* scroll bar modification css */

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem grey;
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #181617;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #181617;
}

.ecs-scrolling-description-sec,
.pagination-scrollable,
* {
  scrollbar-width: thin;
  scrollbar-color: #181617 #bbb;
}

.ecs-scrolling-description-sec::-webkit-scrollbar,
.pagination-scrollable::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.ecs-scrolling-description-sec::-webkit-scrollbar-track,
.pagination-scrollable::-webkit-scrollbar-track {
  background-color: #bbb;
}

.ecs-scrolling-description-sec::-webkit-scrollbar-thumb,
.pagination-scrollable::-webkit-scrollbar-thumb {
  background-color: #181617;
}

.ecs-scrolling-description-sec::-webkit-scrollbar-track,
.ecs-scrolling-description-sec::-webkit-scrollbar-thumb,
.pagination-scrollable::-webkit-scrollbar-track,
.pagination-scrollable::-webkit-scrollbar-thumb {
  border-radius: 10rem;
}

/* modal css start from here */

/*.fade:not(.show) {
opacity: 1;
}
.modal {
display: block;
}*/

.welcome-overlay.modal,
.print-popup-overlady.modal {
  display: block;
}

.welcome-overlay.fade:not(.show),
.print-popup-overlady.fade:not(.show) {
  opacity: 1;
}

.cinfo-popup-overlay,
.payment-popup-overlay,.project-gallery-modal {
  opacity: 1 !important;
  display: block;
}

.project-gallery-modal {
  z-index: 9999;
}

.blur-overlay {
  opacity: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
}

.about-video-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-scrolable-area {
  height: 70rem;
  overflow-y: auto;
  padding-right: 3rem;
}

.modal-content h4 {
  font-size: 2.5rem;
  color: #fff;
  line-height: 2.7rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.about-video-sec img {
  margin-bottom: 2rem;
}

.welcome-overlay .modal-content,
.finished-overlay .modal-content {
  background: transparent;
  box-shadow: none !important;
  border: none;
}

.welcome-overlay .modal-dialog {
  transform: translateY(-50%) !important;
  top: 50%;
}

.start-now-btn:active,
.start-now-btn:focus {
  background-color: #dd8f41;
  outline: none;
  box-shadow: none !important;
}

.modal-content h1 {
  font-size: 4.7rem;
  color: #fff;
  font-weight: 100;
  line-height: 5.7rem;
  margin-bottom: 6rem;
}

.modal-content h2 {
  font-size: 4rem;
  color: #fff;
  font-weight: 100;
  line-height: 4.5rem;
  margin-bottom: 5rem;
}

.modal-content p {
  color: #fff;
  font-size: 2.9rem;
  line-height: 3.6rem;
  font-weight: 100;
  margin-bottom: 4rem;
}

.start-now-btn {
  width: 70%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dd8f41;
  border: none;
  font-size: 1.8rem;
  font-weight: 100;
  line-height: 2.3rem;
  border-radius: 5px;
  margin-top: 7rem;
  color: #fff;
}

.start-now-btn:hover {
  background-color: #dd8f41;
}

.video-play-modal .modal-dialog {
  max-width: 70%;
  margin: auto;
}

.video-play-modal .modal-dialog .modal-body:hover {
  border: none;
}

button.btn-close {
  width: 2.7rem;
  background-size: contain;
  height: 2.2rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  opacity: 1;
  z-index: 2;
}

.video-play-modal .modal-dialog .modal-body {
  padding-bottom: 6rem;
  padding-top: 6rem;
  border: 0;
}
.white-blur-bg {
  border-radius: 2.8rem;
  opacity: 1;
  backdrop-filter: blur(3rem);
  -webkit-backdrop-filter: blur(3rem);
  background-color: rgba(255, 255, 255, 0.6);
}
.videos-slider-popup-overlay .modal-dialog {
  max-width: 70%;
  /* margin: auto; */
}
.onep .white-blur-bg {
  border-radius: 0;
  overflow: hidden;
  border: 0;
}
.videos-slider-popup-overlay .modal-dialog .modal-body {
  padding: 20px 80px !important;
  max-height: 690px;
  overflow-y: scroll;
  margin-right: 73px;
  padding-right: 39px !important;
  margin-bottom: 15px;
}

.videos-slider-popup-overlay .modal-dialog .modal-body img {
  width: 100%;
}

.project_slider_main .review-carousel {
  padding-top: 30px;
  padding-bottom: 40px;
}
.project_slider_main .review-carousel .img-outer-cus {
  height: 320px;
}
.qc-des {
  padding: 1.5rem 2rem;
  padding-top: 0;
  height: 80px;
}
.review-carousel.hide-carousel {
  left: 100%;
  transition: 1.5s ease-in-out;
}

.review-carousel {
  left: 0;
  transition: 1.5s ease-in-out;
}

.outer_videos_modal {
  margin-bottom: 33px;
}
.project_main_content {
  flex-direction: column;
  overflow-x: hidden;
}
.second-popup {
  opacity: 1;
  backdrop-filter: blur(3rem);
  -webkit-backdrop-filter: blur(3rem);
  background-color: rgba(255, 255, 255, 0.6);
}

.second-popup .modal-dialog {
  max-width: 100% !important;
  margin: 0;
}

.second-popup .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.second-popup .modal-dialog .modal-content .modal-body img {
  width: 100%;
  height: 100%;
}

.second-popup button.btn-close {
  top: 0;
}

.video-play-modal .modal-dialog .modal-body:focus,
.second-popup button.btn-close:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.print-popup-overlay .modal-content {
  background: #f0f0f0;
  border-radius: 1.5rem;
  border: none;
  padding: 4rem;
}

.print-popup-overlay .modal-dialog,
.info-popup-overlay .modal-dialog,
.payment-popup-overlay .modal-dialog {
  max-width: 50%;
  top: 50%;
  transform: translateY(-50%) !important;
  margin: auto;
}
.info-popup .modal-dialog {
  max-width: 540px;
  width: 95%;
  top: 50%;
  transform: translateY(-50%) !important;
  margin: auto;
}

.payment-popup-overlay .modal-dialog {
  max-width: 30%;
}

.print-popup-overlay .modal-content h1,
.print-popup-overlay .modal-content p {
  color: #181617;
  margin-bottom: 2rem;
}

.download-btn,
.print-btn {
  display: block;
  background-color: #fff;
  border: none;
  padding: 1rem 1.5rem;
  color: #181617;
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-family: Proxima Nova;
  margin-top: 3rem;
}

.print-popup-overlay .modal-content p {
  margin-bottom: 5rem;
}

.qis-content h5 {
  font-family: inherit;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  line-height: 2.3rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.quote-image-sec img {
  width: 100%;
  height: auto;
}

.quote-image-sec {
  position: relative;
  margin-right: 3rem;
}

.qis-content {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  text-align: center;
}

.qis-content img {
  width: auto;
}

.download-btn:hover,
.print-btn:hover {
  background-color: #181617;
  color: #fff;
}

/* review page css start from here */
.heading-section h1 {
  font-family: proxima-nova, sans-serif;
  font-size: 4.7rem;
  line-height: 5.7rem;
  font-weight: 100;
}

.print-popup-btn-sec a {
  font-size: 2.5rem;
  line-height: 3rem;
  font-family: inherit;
  font-weight: 700;
  color: #181617;
  border: none;
}

.print-popup-btn-sec a img {
  margin-left: 1.5rem;
  width: 4.1rem;
}

.info-btn-sec img {
  width: 3.5rem;
}

.print-info-btn {
  height: auto;
  align-items: flex-start;
}

.info-btn-sec {
  padding-left: 1.5rem;
}

.total-estimate-sec h1 {
  font-family: proxima-nova, sans-serif;
  font-size: 36px;
  line-height: 47px;
  font-weight: 100;
  color: #191816;
  text-align: center;
}

.price-label {
  text-align: right;
  padding-right: 2rem;
}

.price-amount {
  text-align: right;
}

.total-estimate-sec p {
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
}

.total-estimate-sec {
  display: none !important;
}

.review-page .total-estimate-sec {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
.review-carousel {
  padding-top: 90px;
  padding-bottom: 100px;
}

.info-popup-overlay h5 {
  font-size: 3.5rem;
  line-height: 4.2rem;
  text-align: center;
}

.info-popup-overlay h5 .modal-content {
  padding: 3rem;
}

.info-popup-overlay .modal-content .modal-body {
  min-height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-form-overlay .modal-dialog {
  max-width: 50%;
  transform: translateY(-50%) !important;
  top: 50%;
}

.request-form-overlay .modal-content {
  background: #f0f0f0;
  padding: 0;
  border-radius: 1.5rem;
}

.request-form-overlay .modal-body {
  padding: 0;
}

.request-form-overlay p,
.request-form-overlay h1 {
  color: #181617;
}

.request-form-overlay .form-select,
.popup-request-form input {
  font-size: 2rem;
  line-height: 2.4rem;
  border: none;
  height: 5.4rem;
  padding: 0.5rem 2rem;
  margin-bottom: 3rem;
}

.request-form-overlay textarea {
  font-size: 2rem;
  line-height: 2.4rem;
  border: none;
  padding: 0.5rem 2rem;
  width: 100%;
}

.modal .btn-check:active + .btn,
.modal .btn-check:checked + .btn,
.modal .btn.active,
.modal .btn.show,
.modal .btn:active,
.modal .btn-check:focus + .btn,
.modal .btn:focus,
.modal .btn:active:focus {
  background-color: #181617;
  border-color: #181617;
  outline: none;
  box-shadow: none;
}
.popup-request-form h2 {
  color: #181617;
  font-weight: 300;
}

.send-btn,
.delete-btn {
  background-color: #dd8f41;
  border: none;
  padding: 1rem 1.5rem;
  color: #fff;
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-family: Proxima Nova;
  margin-top: 3rem;
  min-width: 18rem;
}

.send-btn:hover,
.delete-btn:hover {
  background-color: black;
}

.rf-left-sec .quote-confirm-box {
  padding-right: 2.5rem !important;
}

.request-form-overlay .modal-content h1 {
  margin-bottom: 3rem;
}

.request-form-overlay .quote-confirm-box {
  width: auto;
  background-color: transparent;
}

.popup-request-form {
  padding-right: 3rem;
}

.request-form-delete h2 {
  color: #ff0000;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4.2rem;
}

.request-form-delete h2 span {
  font-weight: 300 !important;
  color: #181617;
  margin-top: 3rem;
  display: block;
}

/* review page carousal css start from here */
.quote-confirm-box {
  width: 100%;
  /* background-color: #f0f0f0; */
  border-radius: 0 px;
  -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background: #ffffff7a;
}

.qc-header {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  padding-bottom: 3px;
}

.quote-confirm-box h3 {
  font-size: 21px;
  line-height: 27px;
  margin-bottom: 0;
  font-weight: 500;
}
.quote-confirm-sec {
  margin-top: 5rem;
  margin-bottom: 5rem;
  border: 1px solid white;
  padding: 20px 16px 50px;
  position: relative;
}
.price_section {
  text-align: right;
  padding-right: 18px;
  padding-bottom: 18px;
}

.qc-des {
  padding: 1.5rem 2rem;
  padding-top: 0;
}
.qc-des p {
  font-size: 14px;
  line-height: 22px;
  color: #181617;
  font-style: italic;
  width: 80%;
}

ul.qc-list li {
  font-size: 2rem;
  line-height: 2.3rem;
  font-family: inherit;
}

ul.qc-list {
  padding-left: 2.6rem;
}
.qc-header {
  padding-right: 35px;
}
.contact-no {
  background: transparent !important;
  backdrop-filter: unset;
}
.contact-no h3 a,
.contact-no a {
  color: #191816;
  font-size: 16px;
}
.contact-no img.icon_review_print.img-fluid {
  filter: brightness(0);
}
.qc-button .btn {
  background: #b3dadb;
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 1.8rem;
  line-height: initial;
  padding: 0.5rem 1.5rem;
  height: 3.5rem;
  border: none;
}

.qc-button .btn-confirm {
  position: absolute;
  bottom: -80px;
  margin: 0;
  background: #181617;
  border-radius: 0;
  width: 177px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 400;
  left: 50%;
  transform: translateX(-50%);
}
.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #181617;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #62bbbc;
}

.quote-confirm-sec:hover .btn-confirm {
  background-color: #ffffff;
  color: #181617;
}
.quote-confirm-sec {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.qc-button .btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.quote-confirm-box h3.price {
  white-space: nowrap;
  padding-left: 10px;
}

.btn-delete {
  background-color: #e2e0e0 !important;
  height: 3.5rem !important;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  padding: 0.5rem !important;
  min-width: 3.5rem !important;
}

.btn-delete img {
  width: 1.6rem !important;
}

.qc-button {
  display: flex;
  flex-direction: row;
  margin: 1.5rem 0;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.qc-button .btn:active,
.qc-button .btn:hover,
.qc-button .btn:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.review-carousel.owl-theme .owl-nav button.owl-prev:hover,
.review-carousel.owl-theme .owl-nav button.owl-next:hover {
  background-color: transparent;
}

/* review page owl-carousal slider css start from here */
.owl-carousel .owl-nav button.owl-prev .review-prv-img,
.owl-carousel .owl-nav button.owl-next .review-nxt-img {
  display: block !important;
}

.review-carousel.owl-theme .owl-nav {
  text-align: left;
  margin-top: 0;
  display: flex;
  align-items: center;
}

.review-carousel {
  padding-left: 8%;
  margin-left: 0.7rem;
}

.img-outer-cus img {
  border-radius: 0;
}

.review-carousel.owl-theme .owl-nav .owl-dots {
  margin: 0;
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  display: none;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot {
  background: transparent;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot span {
  background-color: #181617;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 100%;
  opacity: 0.49;
}

.review-carousel.owl-theme .owl-nav button img {
  width: 20px;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot {
  margin-left: 0;
  margin-right: 0;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot.active span {
  opacity: 1;
}

.review-carousel.owl-theme .owl-nav button.owl-prev,
.review-carousel.owl-theme .owl-nav button.owl-next {
  margin: 0;
}

/* terms & sign page css start from here */
.terms-page-content {
  background: #525659;
  border-radius: 0;
  padding: 0;
  position: relative;
  height: 100%;
}

.terms-page-content p {
  font-size: 2.5rem;
  line-height: 3.7rem;
  color: #8a8a8a;
  margin-bottom: 3rem;
}

.tpc-scroll-area {
  height: 100%;
  padding: 1.5rem;
}

.signature-sec .nav-link.active {
  color: #62bbbc;
}

.signature-sec .nav-tabs {
  border: none;
}

.signature-sec .nav-link {
  font-size: 3rem;
  line-height: 3.7rem;
  color: #8a8a8a;
  border: none;
}

.tab-content {
  border: solid 1px #707070;
  border-radius: 0.5rem;
  margin-top: 1px;
  padding: 2.5rem;
  text-align: center;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature-sec .tab-content {
  height: auto;
}

.signature-sec .nav-link {
  padding-left: 0;
}

.signature-sec .nav-link + .nav-link {
  padding: 2rem;
}
.terms-page-content .tpc-scroll-area {
  padding: 0px;
}

.accept-sign-btn {
  background: #9BD4D3;
  border-radius: 5px;
  color: #ffffff;
  font-size: 17px;
  line-height: 3.3rem;
  padding: 3rem 1.5rem;
  height: 45px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border-radius: 0rem;
  letter-spacing: 0.1rem;
}

.signature-draw h1 {
  font-family: Lamar Pen;
  font-size: 4.7rem;
  line-height: 5.5rem;
  transform: rotate(-5deg);
  padding-bottom: 0rem;
}

.accept-sign-btn:hover {
  background-color: #181617;
  color: #fff;
}

.tab-content .tab-pane {
  width: 100%;
}

.customer-review-sec h3 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-family: inherit;
  font-weight: 700;
}

/* payments page css start from here */
table.payment-stages tr {
  vertical-align: baseline;
}

table.payment-stages tr th {
  color: #181617;
  line-height: 2.5rem;
  padding: 1rem 0;
}

table.payment-stages tr td {
  color: #040404;
  opacity: 0.7;
  line-height: 2.5rem;
  padding: 1rem 0;
}

table.payment-stages tr th span,
table.payment-stages tr td span {
  font-size: 1.7rem;
}

table.payment-stages tr th:last-child,
table.payment-stages tr td:last-child {
  font-size: 2rem;
  padding-left: 1rem;
}

table.payment-stages tr th:first-child,
table.payment-stages tr td:first-child {
  padding-right: 4rem;
}

.payment-count-area p {
  margin-bottom: 3rem;
  font-weight: 300;
  opacity: 1 !important;
  color: #000;
}
.total-price-box {
  background-color: #181617;
  text-align: center;
  width: 25rem;
  margin-left: auto;
}

.total-price-box h3 {
  font-size: 2.9rem;
  line-height: 3.6rem;
  color: #fff;
  margin: 0;
  padding: 1.5rem;
}

.total-price-box span {
  font-size: 2rem;
  line-height: 2.4rem;
  padding-bottom: 7px;
  display: inline-block;
}

.total-price-box h3 + h3 {
  border-top: 1px solid #afafaf;
}

.card-sec {
  background-color: #f0f0f0;
  padding: 1.5rem 2.5rem;
  border-radius: 15px;
  height: 10.6rem;
  margin-bottom: 1.5rem;
}
.payment-page .card-sec {
  border-radius: 0;
}

.card-sec a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #181617;
  height: 100%;
}

.card-sec h3 {
  font-size: 33px;
  line-height: 3.6rem;
  font-weight: 400;
  margin-bottom: 0;
  color: #1918168f;
}

.card-sec img {
  max-width: 11rem;
  max-height: 5rem;
}

/* progress page css start from here */
.notifications-sec > h3,
.contact-form-sec > h3 {
  margin-bottom: 2rem;
  color: #fff;
}

.notifications-sec ul {
  max-height: 40rem;
  overflow-y: auto;
  padding: 0rem 1.5rem;
}

.notifications-sec ul li h3 {
  font-weight: 100;
  color: #fff;
}

.contact-form-sec textarea {
  width: 100%;
}

.contact-form-sec input {
  font-size: 2rem;
  line-height: 2.4rem;
  height: 5.4rem;
  padding: 0.5rem 2rem;
  background: #f6f6f6;
  border: none;
  border-radius: 0;
}
.contact-form-sec span.succes_msg {
  font-size: 2.6rem;
  margin-bottom: 5px;
  display: block;
  color: #337902;
  margin-top: 10px;
  text-align: center;
}

.contact-form-sec select,
.contact-form-sec textarea {
  background: #f6f6f6;
  border: none;
  padding: 1rem 2rem;
  font-size: 2rem;
  line-height: 2.4rem;
}

.contact-form-sec textarea {
  margin-top: 2rem;
}

.contact-form-sec .send-btn {
  line-height: 2.3rem;
  background: #181617;
  border-radius: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 141px;
  font-size: 19px;
  text-transform: uppercase;
}

/* about us css started from here */
.about-us-page .main-content {
  height: auto;
}

.about-detail-sec p {
  font-weight: 100;
}

.award-wrapper {
  width: 90%;
  height: 25rem;
  background-color: #f7f7f7;
  border-radius: 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem;
}

/* vertical owl carousel css start from here */
.vertical-carousel .slick-prev:before,
.vertical-carousel .slick-next:before {
  display: none;
}

.vertical-carousel .slick-prev {
  left: 0;
  top: calc(100% + 2rem);
  transform: rotate(-90deg);
}

.vertical-carousel .slick-next {
  left: 25px;
  top: calc(100% + 2rem);
  transform: rotate(90deg);
}

.vertical-carousel .slick-slide img {
  margin-bottom: 0.5rem;
}

.chatbot-icon svg {
  padding: 1rem;
}

/* yes-start css start */
.yes-start-screen {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #181617;
  left: 0;
}

.yes-start-screen a {
  text-decoration: none;
  font-size: 2.2rem;
  color: #fff;
  font-weight: 100;
  line-height: 2.7rem;
  text-transform: uppercase;
  text-align: center;
}

.yss-brand {
  position: fixed;
  bottom: 5rem;
  left: auto;
  right: auto;
  opacity: 0;
  transition: 0.9s ease-in-out;
}

.yss-lottie-ani {
  position: absolute;
  bottom: 50%;
  transition: 0.9s ease-in-out;
  opacity: 0;
}

.yss-first-quote {
  position: absolute;
  z-index: 2;
  transition: 0.9s ease-in-out;
  top: 50%;
  transform: translatey(-50%);
}
.yss-welcome-message {
  position: absolute;
  top: 0%;
  transition: 0.7s ease-in-out;
  opacity: 0;
}
.hide-wrapper {
  opacity: 0;
  transition: opacity 0.9s;
}

.optn-btn:active,
.optn-btn:focus {
  background: #dd8f41;
  border-color: #dd8f41;
  outline: none;
  box-shadow: none !important;
}

.modal.second-popup.fade .modal-dialog {
  transition: transform 0.9s ease-out;
  transform: translate(0, 0%) !important;
}
.modal.second-popup.show .modal-dialog {
  transform: none;
}

.modal.video-play-modal.fade .modal-dialog,
.modal.videos-slider-popup-overlay.fade .modal-dialog {
  transition: transform 0.9s ease-out;
  transform: translate(0, 100%);
}
.modal.video-play-modal.show .modal-dialog,
.modal.videos-slider-popup-overlay.show .modal-dialog {
  transform: none;
}

.card-sec:hover {
  background: #34b2b0;
}

.card-sec:hover a, .card-sec:hover a h3{
  color: #fff;
}

.card-sec:hover img {
  filter: invert(1);
}

form.payment-form label {
  font-size: 2rem;
  font-family: proxima-nova, sans-serif;
  color: #505050;
  margin-bottom: 0.5rem;
}

form.payment-form input.form-control,
form.payment-form select.form-select {
  border: none;
  font-size: 2rem;
  color: #aaaaaa;
  padding: 1rem 1.5rem;
}

form.payment-form .input-group {
  border: solid 1px #707070;
}

.paymentpp-btn {
  background: #dd8f41;
  border: solid 1px #707070;
  color: #fff;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
}

.paymentpp-btn:hover {
  background: #707070;
  box-shadow: none;
}

.paymentpp-btn:active,
.paymentpp-btn:focus {
  outline: none;
  box-shadow: none !important;
  border: none;
}
form.payment-form {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 4rem;
}

.blk-logo {
  padding: 4rem;
}

.payment-popup-overlay .modal-content {
  border-radius: 3rem;
}

.progress-step-active h5 {
  color: #000;
}
.progress-step-active .inactive h5 {
  color: #fff;
}
.progress-step-active .inactive h5 {
 
  opacity: 0.5;
}
.progress-step-active h5 {
  color: #fff;
  max-width: 162px;
  text-align: center;
  margin-left: -52px;
  margin-top: 14px;
}
.new_cont {
  display: flex;
  margin-bottom: 38px;
  text-align: left;
  flex-wrap: wrap; 
  flex-direction: row;
  justify-content: flex-end;
}
/* .position-without-animation .new_cont .step {
  width:0%;  
  height: 0;  
  overflow: hidden;
} */
.position-without-animation .new_cont .step.step-one {
  width:16%;  
  overflow: hidden;
}

 
.step.active.act_sx.delivery-truck::after {
  display: none;
}

 

/* .position-with-animation .new_cont .step {
-webkit-transition: width 3s ease-in-out;
-moz-transition: width 3s ease-in-out;
-o-transition: width 3s ease-in-out;
transition: width 3s ease-in-out;
width:16%;   
animation-duration:3s;
overflow: visible;
}
  */
 
.step.step-seven::before {
  display: none;
}
/* .position-with-animation .new_cont .step.step-seven {
  width: 14rem;
} */

.step.active::before {
  opacity: 1;
}
.step.inactive::before {
  opacity: 0.8;
}
 
.position-with-animation .inactive line#Line_25, .position-with-animation .inactive line#Line_26 {
  stroke: #b4a6a6!important;
}
.position-with-animation line#Line_25, .position-with-animation line#Line_26 {
  stroke: #62bbbc!important;
}



/* .right {
  animation: right-animate 1s ease infinite alternate;
}

@keyframes right-animate {
  0% {
      transform: translateX(140px);
  }
  100% {
      transform: translateX(20px);
  }
}

@keyframes center-animate {
  0% {
      transform: scaleX(120);
  }
  100% {
      transform: scaleX(0);
  }
} */

.step.step-ten.nine_active.inactive::before {
  opacity: 1;
}
 
.position-with-animation .active.delivery-truck::after {
  content: "";
  width: 11rem;
  height: 11rem; 
  top: -9px;
  position: absolute;
  background: url(../images/delivery_van.svg);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  background-position: center;
  background-size: contain;
   background-repeat: no-repeat; 
  /*animation-name: move-van; */
  /* animation-duration: 3s;  */
  left: 63%; 
}
 
/* @keyframes move-van{
  0%   {  left:0 ; } 
  100% { left:63%;  }
} */


.step.step-six.active.delivery-truck::after {
  left: 160px;
  right: unset;
  /* animation-name: move-van-top; */
  /* animation-duration: 7s; */
  /* left: unset; */ 
  top: 90px; 
  transform: rotate(90deg) 
 }
/* 
@keyframes move-van-top{
  0%   {  left:0;top:0;transform: rotate(0deg) }
  25%   {  left:160px;top:0rem;transform: rotate(0deg); }
  50%   {  left:160px;top:70px; transform: rotate(75deg) }
  75%   {  left:160px;top:90px;transform: rotate(90deg) }
  100% { left:160px;top:90px;transform: rotate(90deg)  }
}  */

 .step.step-nine.eight_active::before, .step.step-eight.nine_active::before, .step.step-seven.ten_active::before {
  opacity: 1;
}


.step.step-six .step-inner::after {
  background: #fff;
  border-radius: 0 0 50px 50px;
  content: "";
  height: 18rem;
  right: 2.5rem;
  position: absolute;
  top: 25px;
  width: 8px;
  z-index: -1;
  
}

.step.step-eight.seven_active::before, .step.step-eleven.ten_active::before {
  opacity: 1;
}

.step.step-eight.active.delivery-truck:after, .step.step-eleven.active.delivery-truck:after, .step.step-nine.active.delivery-truck:after, .step.step-seven.active.delivery-truck:after, .step.step-ten.active.delivery-truck:after {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -21rem;
  /* animation-name: move-van-ryt; */
  /* animation-duration: 2s;  */
}
/* 
@keyframes move-van-ryt{
  0%   {  left:55rem; }
 
  100% { left:-22rem; }
} */

.step.step-six.inactive .step-inner::after {
opacity: 0.8;
  
}
.step.step-six.active .step-inner::after {
  opacity: 1;
    
  }

  .step.step-ten.seven_active::before{
    opacity: 1;
  }

  .step.inactive h5 {
    opacity: 0.9;
    color: #191816;
}
.step.active h5 {
    opacity: 1;
    color: #3cf3f0;
}
.step.step-six::before {
  border-radius: 50px 0px 0px 50px;
}

.step::before {
  background: #fff;
  border-radius: 50px;
  content: "";
  height: 8px;
  right: 25px;
  position: absolute;
  top: 25px;
  width: 18rem;
  z-index: -1;
}

.new_cont.bottom_animationb {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.new_cont {
  display: flex;
  margin-bottom: 38px;
  text-align: left;
  justify-content: flex-start !important;
  margin-left: auto;
}


@media (max-width:1750px) {
.step:before {
  right: 17px; 
  width: 14rem; 
}
}

@media (max-width:1600px) {
  .step::before { 
    width: 13rem;
    right: 3rem;
  }
  .position-with-animation .active.delivery-truck::after {
     width: 8rem;
    height: 8rem;
    top: 2px; 
    left: 58%;
}
.step.step-six .step-inner::after  {
   right: 3rem; 
}
.step.step-six.active.delivery-truck::after {
  right: -6px;
 top: 14rem;
 /* animation-name: move-van-top;      */
}
.step-img svg {
  width: 6rem;
  height: 6rem;
  margin-top: 5px;
}
}

@media (max-width:1440px) {

  .step.step-six.active.delivery-truck::after {
     right: -6px;
    top: 16rem;
    /* animation-name: move-van-top;      */
}

  /* @keyframes move-van-ryt{
    0%   {  left:17rem; }
   
    100% { left:-17rem; }
  } */

  .step.step-eight.active.delivery-truck:after, .step.step-eleven.active.delivery-truck:after, .step.step-nine.active.delivery-truck:after, .step.step-seven.active.delivery-truck:after, .step.step-ten.active.delivery-truck:after {
     left: -17rem; 
}
  .step::before {
    width: 12rem; 
    right: 4rem;
}
.step::before {
  content: "";
  right: 20px;
  position: absolute;
  top: 25px;
  width: 13rem; 
}
.step-inner svg {
  width: 5rem;
}
}



  .step {
  top: 0;
  position: relative;
  width: 16%;
  padding-bottom: 4rem;
}
.inactive img {
  opacity: 0.3;
}
.step.step-seven {
  width: 7%;
}

 
.position-with-animation .inactive circle {
  fill: #fff;
  opacity: 0.8;
}
.position-with-animation .active circle {
  fill: #fff;
}
.position-with-animation line#Line_25, .position-with-animation line#Line_26 {
  stroke: #62bbbc !important;
}

.cta-main {
  position: relative;
}

.cta-top-border,
.cta-bottom-border {
  position: absolute;
  width: 0%;
  height: 2px;
  background: red;
  right: 0;
  transition: 0.6s width;
}

.cta-top-border {
  top: 0;
}

.cta-bottom-border {
  bottom: 0;
}

.cta-main:hover .cta-top-border,
.cta-main:hover .cta-bottom-border {
  transition: 0.6s width;
  width: 100%;
}

.request-form-overlay textarea:focus-visible {
  outline: none;
}
.info-btn-sec a {
  border: none;
}

.pagination-sec ul.pagination-list li:last-child {
  padding-bottom: 0;
}

.inner_imge_cust {
  height: 200px;
}

.inner_imge_cust img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.team-member-contacts img {
  width: 20px;
}
.owl-carousel .owl-item {
  width: 320px !important;
}
span.err {
  font-size: 1.8rem;
  margin-bottom: 5px;
  display: block;
  color: #ff5722;
}

.review-carousel.owl-theme .owl-nav button.owl-next {
  margin-left: 1.5rem;
}

.gray-lottie-btn.active path {
  fill: #181617;
}

.qc-button .btn.disabled-btn,
.qc-button .btn.disabled-btn:hover,
a.disabled-btn {
  background: #d7d7d7 !important;
}

.signature-draw .sigPad {
  width: 100%;
  height: 100px;
  border-bottom: 2px solid #707070;
  margin-bottom: 1rem;
}
.accept-sign-btn:active {
  background-color: #181617;
  color: #fff;
}

@media (max-width: 1150px) {
  .step.step-eleven::before {
    display: none;
}

  .p_progressname { 
    width: 85%;
    margin: auto;
}

  .position-without-animation .new_cont .step {
    width:100%;  
    height: auto;  
    overflow: hidden;
    overflow: visible;
  }
  .position-without-animation .new_cont .step.step-one {
    width:100%; 
    height: auto;  
    overflow: visible;
  }
  .step::before {
      content: "";
      left: 50%;
      position: absolute;
      top: 12rem;
      width: 1rem;
      height: 13rem;
  }
  
  .new_cont {
    display: flex;
    margin-bottom: 38px;
    text-align: left;
    justify-content: flex-end;
    margin-left: auto;
    flex-direction: column;
    align-items: center;
  }
  
  .new_cont.bottom_animationb {
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  
  .step.step-six .step-inner::after{
    display: none;
  }
  
  .step {
    top: 0;
    position: relative;
    width: 16%;
    padding-bottom: 13rem;
  }
  .step.step-six.active.delivery-truck::after {
    right: unset;
    top: 19rem;
    /* animation-name: move-van-top; */
    left: 47%;
  }
  .new_cont {
    display: flex;
    margin-bottom:0;
    text-align: center;
   
  }
  
  .bg_prrogress {
     margin-left: 10px !important; 
}
  .step { 
    width: 100% !important;
    padding-bottom:16rem;
  }
  .progress-step-active h5 {
    max-width: 100%;
    margin-left: 0;
    margin-top: 4px;
    margin-bottom: 20px;
}
.step.step-six::before {
  border-radius: 50px;
}
.progress-step-active h5 br{
  display: none;
}
.step.step-seven::before {
  display: block;
}
 

.step.step-six.active.delivery-truck::after {
  right: unset;
  top: 19rem;
  /* animation-name: move-van-top; */
  left: unset;
  margin-left: -17px;
}

/* @keyframes move-van-top{
  0%   {  left:unset;top:0; }
 
  100% { left:unset;top:19rem;  }
} */

.step.step-eight.active.delivery-truck:after, .step.step-eleven.active.delivery-truck:after, .step.step-nine.active.delivery-truck:after, .step.step-seven.active.delivery-truck:after, .step.step-ten.active.delivery-truck:after {
  transform: rotate(90deg);
  left: unset;
  /* animation-name: move-van-top;
  animation-duration: 2s;
  margin-left: -21px; */
}
  .step.step-eleven.ten_active::before {
  display: none;
}

.step.step-seven {
  width: 100%;
}

.step.active.delivery-truck::after {
  left: unset;
  transform: rotate(90deg);
  right: unset;
  top: 19rem;
  /* animation-name: move-van-top; */
  animation-duration: 2s;
  left: unset;
  margin-left: -22px;
}
.heading-section {
  text-align: center;
}

.heading-section p br {
  display: none;
}

  }

.sgbuttons {
  width: 100%;
  height: 30px;
}

.tpc-scroll-area iframe {
  width: 100%;
  height: 100% !important;
  min-height: 67vh;
}

.clear_pad_btn {
  display: flex;
  justify-content: center;
}

.clear_pad_btn button.btn {
  background: #191816;
  border-radius: 5px;
  color: #ffffff;
  font-size: 17px;
  line-height: 3.3rem;
  padding: 3rem 1.5rem;
  height: 45px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border-radius: 0rem;
  margin-right: 1rem;
  letter-spacing: 0.1rem;
}
.payment-page.mainbg_payment .main-content.align-items-start.py-5 .container {
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  background: #ffffff7a;
  padding-top: 30px;
  padding-bottom: 30px;
}

.img_upd_secction {
  height: 70%;
  margin-bottom: 2rem;
}
.img_show_image span.err {
  color: red;
}
.img_show_image span.sderr {
  color: #dd8f41;
}

.img_upd_secction input {
  font-size: 2.6rem;
}

.img_show_image span {
  font-size: 2rem;
  color: #181617;
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
}

.img_show_image {
  text-align: left;
}

.type_sig_outer {
  min-height: 100px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #707070;
  align-items: center;
  margin-bottom: 1rem;
}
.type_signature {
  height: 6.5rem;
  width: 100%;
  border: 0;
  padding: 0.5rem 2rem;
  border-radius: 0px;
}
.type_signature:focus-visible {
  outline: none;
  border-color: #39bdbd;
}

.loader-img-outer {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #00000040;
  border-radius: 0px !important;
  z-index: 10301;
}
.loader_img_cont {
  width: 10rem;
}
.cinfo-popup-overlay .popup-request-form h2 {
  text-align: center;
}
input#sign_img, input#sign_img:focus {
  border-radius: 0;
  border: 0;
  outline: none;
  box-shadow: none;
}
.cinfo-popup-overlay .popup-request-form {
  padding-right: 0;
}
.cinfo-popup-overlay .popup-request-form a.btn {
  background-color: #181617;
  font-family: inherit;
  width: 80%;
  color: #fff;
  margin: 13px auto;
}
a.btn.btn-primary.download-btn.close {
  background: #62bbbc; 
  border-radius:0 ;
}
a.my-0.btn.btn-primary.download-btn.close:hover {
  background: #000;
}
.cinfo-popup-overlay .popup-request-form a.btn:hover {
  background-color: #62bbbc;
}
.cinfo-popup-overlay .popup-request-form a.btn:last-child {
  background: #62bbbc;
  color: #fff;
}
.cinfo-popup-overlay .popup-request-form a.btn:last-child:hover {
  background: #181617;
  color: #fff;
}
.qoutefirst_section {
  position: relative;
}
.info-popup .modal-dialog .modal-body {
  padding: 0;
}
.info-popup .modal-dialog .modal-body h2 {
  padding: 10px;
  background: #181617;
  color: #fff;
  font-size: 20px;
  margin-bottom: 25px;
}

.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover {
  box-shadow: none;
}
.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.hide-btn {
  display: block !important;
}
.Review_bottom_btn {
  display: none;
}
.video_heading_modal h3 {
  padding: 32px 0px 0px 30px;
  font-size: 21px;
  color: #181617;
}
.info-popup-overlay .modal-dialog {
  max-width: 70% !important;
}

.pdf_document_main.modal_project_pdf {
  border: 0;
}
.pdf_document_main.modal_project_pdf ul.pdf_schroll_slider li {
  margin-right: 25px;
  list-style: none;
}
.pdf_document_main.modal_project_pdf ul.pdf_schroll_slider li a span {
  display: block;
  font-size: 20px;
  margin-top: 10px;
}
.pdf_document_main.modal_project_pdf h3 {
  text-align: left;
  font-size: 24px;
}

.pdf_document_main.modal_project_pdf {
  border: 0;
  padding: 0px 20px 0px 50px;
}
.pdf_document_main.modal_project_pdf ul.pdf_schroll_slider li a {
  line-height: 23px;
}
.pdf_document_main.modal_project_pdf ul.pdf_schroll_slider li a img {
  width: 132px;
}
.pdf_document_main.modal_project_pdf ul.pdf_schroll_slider {
  padding: 0;
}
.main_scroll_product {
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
}
.main_scroll_product .outer_videos_modal {
  margin-right: 20px;
  flex: 0 0 auto;
  width: 32%;
  max-height: 247px;
  overflow: hidden;
  object-fit: cover;
}
.main_scroll_product .outer_videos_modal img {
  height: 100%;
  object-fit: cover;
  object-position: bottom center;
}
.outer_videos_modal video {
  width: 100%;
  object-fit: cover;
  border: 0;
  background: transparent !important;
  outline: none !important;
  box-shadow: none;
  object-position: bottom center;
}
.video_heading_modal h3.bottom_header_gallrey {
  padding-left: 0;
  margin-left: -50px;
  padding-bottom: 25px;
}
section.price-section-reviews {
  width: 100%;
}

.handle_price_vs .selected_handle_btn:hover,
.handle_price_vs .select_handle_btn:hover {
  color: #fff;
  border: 1px solid #3abebe !important;
  background: #3abebe !important;
}

.right_banner_product a {
  height: 160px;
  display: flex;
  width: 100%;
  object-fit: cover;
}

.right_banner_product a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  background-color: #fff;
}

.check_disable_qty .form-control.qty_product {
  max-width: 50px;
  height: 22px;
  margin: 0 5px;
  border: 1px solid #181617;
  border-radius: 0;
  color: #191816;
  background: transparent;
  font-size: 13px;
  opacity: 1 !important;
  padding: 3px;
  padding-right: 0;
}
.extra_enhanacments_product {
  margin-top: 30px;
}
.extra_enhanacments_product h5 {
  margin-bottom: 2px;
}
.ecs-scrolling-description-sec .Notes_to_customer h5 {
  margin-bottom: 10px;
  margin-top: 25px;
}

/*.navbar-expand-md .navbar-nav li.nav-item:nth-child(4) a, .navbar-expand-md .navbar-nav li.nav-item:nth-child(5) a, .navbar-expand-md .navbar-nav li.nav-item:nth-child(6) a {
    pointer-events: none;
}*/
.navbar-expand-md .navbar-nav li.nav-item.activemenu {
  opacity: 1;
}

.navbar-expand-md .navbar-nav li.nav-item {
  opacity: 0.3;
}

.navbar-expand-md .navbar-nav li.nav-item a {
  cursor: not-allowed;
}

.navbar-expand-md .navbar-nav li.nav-item.activemenu a {
  cursor: pointer;
}
.navbar-expand-md .navbar-nav li.nav-item.activemenu:hover a span {
  color: #34b2b0;
}
.check_disable_qty .form-control.qty_product::placeholder {
  color: #fff;
}
.checkbox_price_outer .checkbox .check_main_select label {
  display: flex;
  max-width: 100%;
  font-size: 13px;
  line-height: 15px;
}
.checkbox_price_outer .checkbox .check_main_select label:last-child {
  margin-left: 24px;
}

.check_main_select {
  width: 35%;
}
.checkbox_price_outer .checkbox .check_main_select label span {
  margin-right: 7px;
}
.ecs-scrolling-description-sec .window_ref_sec h5 {
  margin-bottom: 1px;
  font-size: 14px !important;
}

 
.inlie_flex_item {
    display: flex;
    margin-top: 10px;
    margin-bottom: 12px !important;
}
.ecs-scrolling-description-sec .window_ref_sec h5 b {
    display: inline-block;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5:last-child {
  margin: 0 !important;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5 span {
  display: block;
  color: #191816;
  text-align: right;
  margin-top: 3px;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item .left_wref h5 span {
  text-align: left;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5 {
  margin-right: 2px !important;
  font-size: 12.2px !important;
  text-align: center;
} 
.right_wref {
  /* width: 70%; */
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-end; */
  /* text-align: right !important; */
  /* width: 480px; */
}
.project_main_content h5.qty_window_ref.last_items_total_price {
  width: 47px;
}

.project_main_content  h5.qty_window_ref {
  width: 24px;
}

.project_main_content h5.ref_window_name {
  width: 24px;
}

.project_main_content h5.dims_window_ref {
  width: 125px;
}

.wref_product_slider {
  display: flex;
  justify-content: space-between;
  width: 70%;
  text-align: left !important;
}

.product_price_wref { 
  width: 30%;
  text-align: right;
}
 
.ecs-scrolling-description-sec .window_ref_sec h5 b {
  display: inline-block;
  font-weight: 500 !important;
}

.video-play-modal img {
  width: 100%;
}

.video-play-modal img.pp-video.img-fluid {
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
}

.video-play-modal .modal-body.p-0 {
  max-height: 825px;
  object-fit: cover;
  width: 100% !important;
  overflow: hidden;
  border-radius: 0 !important;
}

.green_check .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #dbdbdb;
  background-color: transparent;
  border: 1px solid #181617;
}

.check_main_select .green_check .checkmark {
   top: 50%;  
  transform: translateY(-50%);
}
.check_main_select {
  width: 35%;
  display: flex;
  align-items: center;
  min-width: 190px;
}

.green_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  background-color: transparent;
  border: 1px solid #181617;
}

.green_check {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.green_check .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  top: -1px;
  left: -1px;
}

.green_check input:checked ~ .checkmark::after {
  display: block;
  background: #34B2B0;
  width: 20px;
  height: 20px;
  border: 1px solid #34B2B0;
}

.estimate-right-sec.project_vs_right p.top_price_schroll.bottom_total_project span, .estimate-right-sec.project_vs_right p.top_price_schroll.bottom_total_project {
  color: #fff;
  padding: 0;
  font-size: 17px;
  margin-top: 4px;
  margin-bottom: 0;
  white-space: nowrap;
  font-weight: 400;
}

.specification_products_bottom.box ul {
  margin-left: 15px !important;
}


span.checkmark.disabled_checkbox {
  cursor: not-allowed;
}

.sign_contract_center {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
}
.sign_contract_center h2 {
  color: #fff;
  width: 49%;
  margin: auto;
  padding-left: 100px;
}

.sing_contact_btn {
  background: #34b2b0;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
  opacity: 0.7;
}
.disable_cont_btn {
  pointer-events: none;
}
.outer_disable_sing {
  cursor: not-allowed;
}
.sing_contact_btn:hover {
  background: #181617;
  color: #fff;
  text-decoration: none;
}

.sign_contract_buttons {
  position: absolute;
  right: 100px;
  top: 90%;
}

.quote-confirm-sec:hover .btn_sected_all {
  background-color: #181617;
  color: #fff;
}

.signature-sec {
  margin-top: 20px;
  margin-bottom: 20px;
}
.handle_price_vs .form-control.disable_hanld_qty {
  background: #545453;
  border-color: #545453;
  color: #fff;
  cursor: not-allowed;
}
.disable_product_select {
  cursor: not-allowed;
}


.outer_make_form {
  position: relative;
  min-height: 100vh;
}

.contact-form-sec {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: auto;
  max-width: 900px;
  background: #ffffff7a;
  backdrop-filter: blur(19px);
  padding: 49px;
}
.contact-form-sec h3 {
  color: #181617;
  font-size: 30px;
  margin-bottom: 20px;
}
.contact-form-sec span.succes_msg {
  font-size: 2.6rem;
  margin-bottom: 5px;
  display: block;
  color: #337902;
  margin-top: 10px;
  text-align: center;
  position: absolute;
  right: 48px;
  bottom: 41px;
}

.contact-form-sec textarea:focus,
.contact-form-sec input:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}
h3.amount_section {
  text-align: center;
  margin-bottom: 33px;
  font-size: 24px;
}

.contact-form-sec .send-btn:hover {
  background: #3abdbd;
}
.succpaymentmsg {
  font-size: 15px;
  text-align: center;
  color: #3abdbd;
}

.succpaymentmsg h3 {
  font-size: 24px;
  padding-bottom: 27px;
}

.blk-logo.text-center img {
  width: 80%;
}

.errpaymentmsg {
  font-size: 15px;
  text-align: center;
  display: flex;
  color: red;
}

.payment_success h3 {
  margin-top: 40px;
  font-size: 30px;
  color: #3abdbd;
}
ul.pdf_schroll_slider.modal_pdf_products {
  overflow: unset;
}

.pdf_document_main ul.pdf_schroll_slider.modal_pdf_products li a b {
  left: 7px;
  font-weight: 400;
  white-space: nowrap;
  font-size: 15px;
  bottom: -30px;
}
#exampleModalToggle3
  .pdf_document_main.modal_project_pdf
  ul.pdf_schroll_slider
  li {
  margin-right: 25px;
  list-style: none;
  width: 10%;
  margin-bottom: 35px;
  min-width: 110px;
}
#exampleModalToggle3
  .pdf_document_main.modal_project_pdf
  ul.pdf_schroll_slider {
  padding: 0;
  width: auto;
  flex-wrap: wrap;
}
div#exampleModalToggle3 .modal-body {
  overflow-x: clip !important;
  overflow-y: auto;
  min-height: 400px !important;
}

#exampleModalToggle3 .pdf_document_main.modal_project_pdf {
  height: auto;
}
.schroll_product_discrp {
  max-height: 100px;
  overflow: auto;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}



input[type=number] {
  -moz-appearance: textfield !important;
  appearance: textfield;
}
#\32 0-18 {
  -moz-appearance: textfield !important;
  appearance: textfield;
}

.review-carousel .owl-nav.disabled button {
  display: none;
}

.lable_flex1 {
  width: 95px;
}

.lable_flex2 {
  width: 59px;
}

.lable_flex3 {
  width: 90px;
  margin-left: 5px;
}


.customer_detailss {
  display: flex;
  justify-content: space-between;
}
.estimate-right-sec.project_vs_right.text-right p {
  text-align: right;
}
 .estimate-left-sec.top_estimated_vs .customer_detailss p {
  color: #fff !important;
  opacity: 1 !important;
  margin-right: 21px;
}

section.price-section-reviews .container {
  /* background: rgba(255, 255, 255, 0.8); */
  -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background: #ffffff7a;
  margin-bottom: 1%;
  padding: 0;
}


div#prd_gallery_image .modal-dialog {
  max-width: 70%;
  border: 0 !important;
}
div#prd_gallery_image .modal-dialog .modal-body.p-0 {
max-height:825px;
overflow: hidden;
object-fit: fill !important;
object-position: top;
border-radius: 0;
border: 0;
}

div#prd_gallery_image .modal-dialog .modal-body.p-0 img {
object-fit: fill !important;
object-position: center center;
width: 100% !important;
height: 100% !important;
}

.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5.qty_window_ref {
  min-width: 60px;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item .ref_window_name {
  min-width: 200px;
  max-width: 200px;
}

.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5.last_items_total_price {
  min-width:115px;
}

.qc-des .left_detail_product .inlie_flex_inner_item h5.ref_window_name {min-width: 60px;max-width: 60px;}

.qc-des .left_detail_product .inlie_flex_inner_item h5.dims_window_ref {
    width: 155px;
}
.qc-des .left_detail_product .inlie_flex_inner_item h5.last_items_total_price {
    width: 190px;
}


.handle_banner_vs a {
  background: transparent !important;
  padding: 0;
}

.no_video_lable {
  font-size: 17px;
  font-weight: 300;
} 
.review-page .videos_right_buttons {
 max-width: 70rem;
}

.bg_prrogress {
  width: 100%;
  margin-left: 50px;
  margin-right: 10px;
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(66px);
   background: #ffffff7a;   
  padding-top: 50px;
  padding-bottom: 60px;
  min-height: 88vh;
}
.bg_prrogress .container {
  padding: 0;
}

 

.p_progressname {
  color: #191816;
}

.col-md-9.col-lg-10.terms-page.position-relative .main-content.align-items-start.py-5 .container {
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  background: #ffffff7a;
  padding: 0;
}

.term_head_section span {
  color: #fff;
  display: flex;
  font-size: 19px;
}
.term_head_section h2 {
  color: #fff;
  display: flex;
  font-size: 30px;
  line-height: 43px;
  margin-bottom: -14px;
}
.term_head_section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.term_sub_head_section p {
  color: #fff;
  padding: 0px 20px;
  max-width: 683px;
  margin-bottom: 30px;
}
.innr_section_pdf a:last-child {
  background: #9BD4D3;
  color: #fff;
  text-decoration: none;
  padding: 6px 10px;
  display: inline-block !important;
  width: 100px;
  text-align: center;
  margin: 11px auto;
} 
.signature-sec .pdf_doc_section .innr_section_pdf b {
  font-size: 11px;
  display: inline-block;
  margin-top: 10px;
}
.innr_section_pdf.pdf_setion_view {
  flex-direction: row;
}
.signature-sec .pdf_doc_section .innr_section_pdf {
  text-align: center;
  max-width: 335px;
  margin: 20px auto;
}
.term_condition_sec {
  display: flex;
  justify-content: center;
}
.customer-review-sec .signature-sec nav button {
  background: transparent !important;
  color: #191816;
}
.customer-review-sec {
  padding: 10px 30px;
}

.term_condition_sec p {
  color: #191816;
  max-width: 320px;
  font-size: 19px;
  margin-left: 30px;
}

html body div .term_condition_sec input {
  width: 35px; 
}
.col-md-9.col-lg-10.terms-page.position-relative .main-content.align-items-start.py-5 .container .row:first-child {background: #191816;margin: 0px;}

.right_banner_product {
    position: relative;
}

.desabled_product_section .img_diable_pro_Sec {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    background: #ffffff33;
    z-index: 99;
}

.desabled_product_section .img_diable_pro_Sec img {
    width: 70px;
} 


.perun_product{
  border: 1px solid #000; 
   height: 22px;
  margin: 0;
  padding-left: 3px;
  padding-top: 2px;
  
}
 
.qc-button .btn-confirm.active:hover {
    background: #fff;
}
.qc-button .btn-confirm.active {
    background: #62bbbc;
}

.disable_product_select:hover{
  background:#62bbbc!important;
}
.trustpilot-widget {
  margin-top: 50px !important;
  margin-bottom: 20px;
}

.handle_price_vs .form-control.qty_product {
  pointer-events: none !important;
  color: #181617 !important;
  border: 1px solid #181617 !important;
  background: transparent !important;
  outline: none !important;
}
.handle_price_vsdisable_qtya .form-control.qty_product::-webkit-outer-spin-button,
.handle_price_vs.disable_qtya .form-control.qty_product::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.handle_price_vs.disable_qtya .form-control.qty_product[type=number] {
  -moz-appearance: textfield;
}
.container-fluid.team-page.outer_team_pagesvs .container .header_team_vs h1  {
  color: #fff !important; 
}

.hidden_trickle_text {
  visibility: hidden !important;
  font-size: 0px !important;
  opacity: 0 !important;
}
div#prd_gallery_image .inline_vs_content {
  display: inline-block;
  text-align: center;
}

div#prd_gallery_image .modal-dialog .inline_vs_content .modal-body.p-0 {
  overflow: auto;
  width: 100%;
}
div#prd_gallery_image .modal-dialog .inline_vs_content .modal-body.p-0 img {
  width: auto !important;
  height: 100% !important;
  max-width: 100% !important;
}
.Notes_to_customer.specification_products_bottom ul {
  padding-left: 20px;
}

.q_number a.btn.img-popup-btn {
  padding: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  border: 0px;
}
p.select_product_text {
  font-size: 14px;
  margin-top: -9px;
  margin-bottom: 10px;
}
#modal-terms-condition .modal-content .modal-body {
  padding-left: 39px !important;
}
#modal-terms-condition .modal-content p {
  color: #000;
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 17px;
}
#modal-terms-condition .modal-content p b { 
  margin-bottom: -10px !important;
 
}
.q_number {
  text-align: right;
}
.customer_detailss.flex-coloumn {
  flex-direction: column;
}

.estimate-right-sec.project_vs_right.quotenom {
  width: 130px;
}

/* responsive css start from here */

/* Small devices (landscape phones, 576px and up) */

@media (min-width:768px) {
  .active.aos-init.aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
  }
 


}

@media (max-width: 1440px) {

  .bg_prrogress {
     margin-left: 20px; 
  }
 

  }
  


  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    
.videos_right_buttons a img {
    width: 17px; 
}
.videos_right_buttons a {
    font-size: 12px;
}
.estimate-content-section.project_vs_left {
   margin-left: 8px;
}
  }
 
/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* body {
      height: 100vh;
      overflow-y: hidden;
    } */
  .navbar-expand-md,
  .navbar-expand-md .navbar-nav {
    flex-direction: column;
  }

  .side-bar {
    position: fixed;
    width: 25%;
    max-width: 356px;
  }

  .main-content {
    height: 100%;
    min-height: 100vh;
    padding-bottom: 100px !important;
  }
  .col-md-9.col-lg-10.review-page.position-relative
    .main-content.align-items-start.py-5.flex-column {
    padding-bottom: 0 !important;
  }
  .side-bar .navbar {
    height: 100vh;
    position: relative;
  }

  .side-bar .navbar .chatbot-sec {
    position: absolute;
    left: 50%;
    bottom: 4rem;
    transform: translateX(-50%);
  }
  .footer-section .chatbot-sec {
    display: none !important;
  }

  .dt_url {
    font-size: 1.6rem;
    color: #fff;
  }
  .dt_url a {
    font-size: 1.4rem;
    text-decoration: navajowhite;
    color: #3abcbc;
  }

  .glass-page .main-content .container {
    padding-left: 0;
    padding-right: 0;
  }

  .welcome-overlay .modal-dialog {
    max-width: 50%;
  }

  .finished-overlay .modal-dialog {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
  }

  .payment-popup-overlay .modal-dialog {
    max-width: 30%;
  }
  .footer-section {
    overflow: hidden;
  }

  .about-us-page .footer-section {
    background-color: #fff;
    bottom: -100px;
  }
}

 

@media (min-width: 992px) {
  p.top_price_schroll.bottom_total_project {
    margin-top: -6px;
    margin-bottom: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* team page custom animation css */
  .width-without-animation .team-member {
    opacity: 0;
    transition: opacity ease-in 1s;
  }

  .width-without-animation .second-tm,
  .width-without-animation .third-tm,
  .width-without-animation .fourth-tm {
    position: absolute;
    left: 0%;
    transition: ease-in 1s;
  }

  .width-with-animation .second-tm {
    position: absolute;
    left: 25%;
    transition: ease-in 1s;
    opacity: 1;
  }

  .width-with-animation .third-tm {
    position: absolute;
    left: 50%;
    transition: ease-in 1s;
    opacity: 1;
  }

  .width-with-animation .fourth-tm {
    position: absolute;
    left: 75%;
    transition: ease-in 1s;
    opacity: 1;
  }
}
 
@media (max-width: 1300px) {
 
 
.col-md-12.col-lg-7.product_banner_sec .right_banner_product {
    margin-top: 17px;
    margin-bottom: 12px;
}

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .videos_right_buttons {
    margin-bottom: 80px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */

@media (min-width: 1600px) {
  .inner_handle_product { 
    max-height: 250px; 
    min-height: 190px;
}

.team-member {
  margin-top: 4rem;
  max-width: 260px;
}

.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5 {
  margin-right: 6px !important;
  font-size: 2rem !important;
  text-align: center;
 
}
.right_banner_product a {
  height: 200px;
  display: flex;
  width: 100%;
  object-fit: cover;
}
}

@media (min-width: 1440px) {
  .lable_flex1 {
    width: 114px;
}
.check_disable_qty {
    min-width: 400px;
}
.check_disable_qty P{
  font-size: 13px !important;
}
}

@media only screen and (max-width: 1599px) {
 
  .navbar-brand img.brand-logo {
    width: 95%;
  }
}

@media only screen and (max-width: 1440px) {
  .right_banner_product {
    margin-top: 12px;
    margin-bottom: 15px;
}
.right_banner_product a {
  width: 95%;  
  max-width: 370px;
  height: 190px;
  margin-left: auto !important;
}
   .Review_bottom_btn .review_project {
    width: 120px;
    height: 50px;
    font-size: 22px;
  }
  .col-md-12.col-lg-5.product_detail_list, .col-md-12.col-lg-7.product_banner_sec {
    width: 100%;
}

  .approved_button_right .btn {
    width: 120px;
    height: 50px;
    font-size: 22px !important;
  }

  .checkbox_price_outer .checkbox .check_main_select label:last-child {
    margin-left: 23px;
    font-size: 13px;
    line-height: 15px;
  }

  .review-carousel {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  .total-estimate-sec h1 {
    font-size: 25px;
    line-height: 35px;
  }

  .check_main_select {
    width: 50%;
    min-width: 190px;
  }
  .checkbox_price_outer .checkbox .check_main_select label span {
    margin-right: 15px;
  }
  .video-play-modal img {
    max-height: 90vh;
    width: 100%;
  }
}

@media only screen and (max-width: 1366px) {
  .quote-confirm-box h3 {
    font-size: 16px;
    line-height: 22px;
  }

  html {
    font-size: 40%;
  }

  img.brand-logo {
    width: auto;
    max-width: 90%;
  }

  .navbar-nav li a span {
    font-size: 1.7rem;
  }

  .navbar-nav li a img {
    width: auto;
    margin: auto;
    height: 22px;
  }

  .estimate-content-section {
    width: 80rem;
    height: 60rem;
  }
  .navbar-nav li a {
    margin-bottom: 1rem;
  }

  .ecs-scrolling-description-sec {
    height: 45rem;
  }

  .contact-no h3 {
    font-size: 2.6rem;
    line-height: 3.4rem;
  }

  /* safari-only css*/

  @media not all and (min-resolution: 0.001dpcm) {
    .estimate-content-section {
      width: 80%;
    }
    .contact-no,
    .cta-btn .btn {
      padding-right: 10px;
      padding-left: 10px;
    }
    .contact-no {
      height: 70px;
      border-radius: 20px;
    }
    .cta-btn,
    .popup-cta-btn {
      height: 70px;
      width: 220px;
      border-radius: 20px;
    }
    .popup-cta-btn img {
      width: 40px;
    }

    .pagination-sec {
      border-radius: 20px;
    }
    .chatbot-icon {
      width: 60px;
      height: 60px;
    }
    .chatbot-icon svg {
      padding: 4px;
    }
    .qc-button .btn {
      padding: 0.5rem 0.5rem;
      align-items: center;
      display: flex;
    }
    .card-sec img {
      max-width: 75px;
    }
  }
}

@media (max-width: 1780px) {
  .navbar-nav li a span {
    font-size: 3rem;
    line-height: 3.1rem;
    margin-left: 12px;
  }

  .qc-button .btn-confirm {
    bottom: -66px;
    width: 120px;
    height: 42px;
    font-size: 17px;
  }
  .estimate-content-section.project_vs_left {
    width: 142rem !important;
    max-width: 97% !important;
  }

  .side-bar {
    max-width: 290px;
  }

  .outer_start_main img {
    width: 85%;
  }
  .outer_start_main a.button_start_glazing {
    margin: 25% auto;
    max-width: 247px;
    height: 56px;
    font-size: 21px !important;
    font-weight: 100 !important;
  }
  .outer_start_main p {
    font-size: 16px !important;
  }
  .owl-carousel.review-carousel .owl-item {
    width: 320px !important;
  }
  .img-outer-cus {
    height: 200px;
  }
  .project_slider_main .review-carousel .img-outer-cus {
    height: 150px;
  }
  .estimate-content-section.project_vs_left .ecs-scrolling-description-sec {
    height: 80rem;
  }
  .quote-confirm-sec {
    border: 1px solid white;
    padding: 12px 5px 40px;
  }
}

@media (max-width: 1440px) {
  .outer_start_main a.button_start_glazing {
    margin: 30% auto;
    max-width:348px;
    height: 55px;
  }

  .outer_start_main img {
    max-width: 290px;
  }

  .pdf_document_main ul.pdf_schroll_slider.modal_pdf_products li a b {
    white-space: normal;
    font-size: 12px;
    bottom: -37px;
    line-height: 17px;
  }
  #exampleModalToggle3
    .pdf_document_main.modal_project_pdf
    ul.pdf_schroll_slider {
    padding: 0;
    width: auto;
    flex-wrap: wrap;
    padding-right: 8px;
  }
  #exampleModalToggle3
    .pdf_document_main.modal_project_pdf
    ul.pdf_schroll_slider
    li {
    min-width: 95px;
  }
  #exampleModalToggle3
    .pdf_document_main.modal_project_pdf
    ul.pdf_schroll_slider
    li
    img.img-fluid {
    width: 75px !important;
  }

  .estimate-content-section.project_vs_left .ecs-scrolling-description-sec {
    height: 70rem;
  }
  .owl-carousel.review-carousel .owl-item {
    width: 341px !important;
  }
  body,
  html {
    overflow-x: hidden;
    width: 100% !important;
  }
  .pdf_document_main.modal_project_pdf ul.pdf_schroll_slider li a span {
    font-size: 15px;
  }
  .video_heading_modal h3 {
    font-size: 18px;
  }
  .pdf_document_main.modal_project_pdf h3 {
    font-size: 17px;
  }
}

@media (max-width: 1199px) {
  .card-sec img {
    width: 8rem;
  }

  .card-sec h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .total-estimate-sec h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}

@media (max-width: 1024px) {
  
  .check_disable_qty {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 380px;
}
.checkbox_price_outer .checkbox {
  position: relative !important;
  display: flex;
  justify-content: unset;
  margin-top: 14px;
  align-items: unset;
  width: 100%;
}
  .ecs-scrolling-description-sec {
    padding-left: 10px;
    padding-right: 10px; 
}
.contact-no h3 a {
   padding-left: 0; 
   font-size: 14px;
}
.right_banner_product a img { 
  object-fit: cover;
  object-position: center center; 
}
  .checkbox_price_outer .checkbox { 
    flex-direction: column;
  }
  .check_main_select {
    width: 100%;
  }
  .check_disable_qty { 
    margin-top: 10px;
}
  li.checkbox.active_buttons p.disable_btn_first {
    padding: 2px;
    width: 100%;
  }
  .estimate-content-section p.last_total_cost {
    min-width: 103px;
  }
  .videos-slider-popup-overlay .modal-dialog .modal-body {
    margin-bottom: 30px;
    margin-right: 6px;
    padding: 13px !important;
  }
  .video_heading_modal h3.bottom_header_gallrey {
    padding-left: 0;
    margin-left: 0;
    padding-bottom: 25px;
  }
  .pdf_document_main.modal_project_pdf {
    border: 0;
    padding: 0px 20px 0px 0px;
  }
  .video_heading_modal h3 {
    padding: 32px 0px 0px 13px;
  }
  .review-page .total-estimate-sec .price-label h1 {
    font-size: 19px;
  }
  .total-estimate-sec h1 {
    font-size: 19px;
    line-height: 28px;
  }
  .review-carousel {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .modal-dialog {
    max-width: 95% !important;
    margin: auto !important;
  }
  .estimate-content-section {
    width: 75rem;
    height: 60rem;
  }
  .total-estimate-sec h1 {
    font-size: 3.7rem;
    line-height: 4.7rem;
  }

  .main-content .container,
  .main-content .container-fluid,
  .footer-section .container {
    max-width: 94%;
  }
  .contact-no {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .review-carousel {
    padding-left: 0;
  }
   

  a.navbar-brand {
    margin-bottom: 0rem;
  }

  .navbar-nav li a {
    margin-bottom: 0.5rem;
  }

  .side-bar .navbar .chatbot-sec {
    padding: 1.5rem 0 !important;
  }

  .main-content .container,
  .main-content .container-fluid {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .payment-popup-overlay .modal-dialog {
    max-width: 50%;
  }
  .estimate-content-section p.last_total_cost {
    min-width: unset;
}
}

@media (max-width: 991px) {

  .handle_banner_vs img {
    width: 100%;
    height: 190px; 
}


  .estimate-content-section.project_vs_left { 
    max-width: 100% !important;
}
.handle_banner_vs { 
  max-height: 190px; 
  min-height: 190px;
}
.specification_products_bottom.box {
  margin-top: 20px;
}

  
  .total-price-box,
  .estimate-content-section {
    width: 100%;
  }

  .pagination-sec {
    width: 7.5rem;
    padding: 2rem 0.5rem;
  }
  .rgt-sec {
    display: flex;
    justify-content: space-between;
  }

  .rgt-sec h3 {
    margin-bottom: 0;
  }

  .estimate-right-sec {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .total-estimate {
    padding: 1rem 2rem;
  }
  .total-btn {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .brand-list {
    margin: 3rem 0;
  }
  .brand-list img {
    width: 7rem;
  }
  .qc-button .btn {
    padding: 0.5rem 1rem;
  }
  .total-estimate-sec h1 {
    font-size: 3.2rem;
    line-height: 3.4rem;
  }
  .member-profile-pic {
    height: 15.2rem;
    width: 15.2rem;
    max-width: 96px;
    max-height: 96px;
  }
  .team-member-contacts img {
    width: 20px;
  }

  .contact-no h3 {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}

.col-md-4.col-6.position-relative .popup-cta-btn.print-info-btn.ms-auto {
  display: none;
}
.info-btn-sec {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .estimate-content-section.project_vs_left.small_box_review , .videos_right_buttons.hidden_mobile_btn_sec{
    display: none;
}
  .Review_bottom_btn {
   right: 10px;
}
 

.videos_right_buttons a {
  margin: 0 0 5px 0;
}

.videos_right_buttons a:last-child {
  margin: 0;
}
  .main-content .container, .main-content .container-fluid, .footer-section .container {
    max-width: 97% !important;
}
.main-content .container, .footer-section .container {
  max-width: 97% !important;
}

  .sign_contract_center h2 {
    width: 100%;
    margin: auto;
    padding-left: 0;
  }
  .navbar-nav li a span {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
    margin-left: 10px !important;
  }

  .pdf_document_main ul.pdf_schroll_slider.modal_pdf_products li a b {
    white-space: normal;
    font-size: 12px;
    bottom: -37px;
    line-height: 17px;
  }

  .contact-form-sec .send-btn {
    max-width: 120px;
  }
  .contact-form-sec {
    padding: 20px;
  }
  .contact-form-sec span.succes_msg {
    font-size: 14px;
    margin-top: 18px;
    text-align: center;
    position: unset;
  }
  .contact-form-sec h3 {
    font-size: 23px;
    margin-bottom: 15px;
  }

  .extra_enhanacments_product p {
    font-size: 10px !important;
  }
  .img-outer-cus {
    height: 250px;
  }
  .aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  .owl-carousel.owl-theme.review-carousel {
    transform: none !important;
    opacity: 1 !important;
  }

  .total-estimate-sec.col-md-12.col-lg-7.d-flex.align-items-start.py-md-5.py-3 {
    justify-content: flex-start;
  }
  .review-page .total-estimate-sec {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    padding-bottom: 34px !important;
  }
  section.price-section-reviews .col-md-12 {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .approved_button_right {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  section.price-section-reviews .col-md-12 {
    display: flex;
    align-items: center !important;
    justify-content: center;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .videos_right_buttons {
    margin-left: 10px;
  }
  .right_banner_product a {
    height: 220px;
    margin-left: unset;
    max-width: 370px;
    width: 100%;
    margin: auto !important;
}
.estimate-content-section.project_vs_left .right_schroll_vs p {
  text-align: center;
}
.right_banner_product {
  max-width: 370px;
  margin: 10px auto;
}
.handle_option_product {
  max-width: 370px;
  margin: auto;
  padding-top: 15px;
}
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .project_slider_main .videos_right_buttons {
    margin: 0px auto;
    width: 100%;
}
  p.disable_btn_first.last_total_cost {
    min-width: 108px;
  }
  .checkbox_price_outer .checkbox .check_main_select label span {
    margin-right: 16px;
  }

  .estimate-content-section.project_vs_left {
    width: 135rem !important;
    max-width: 97% !important;
    margin: auto !important;
  }
  .review-page .videos_right_buttons {
  max-width: 70rem;
  margin: 20px auto;
  margin-left: 0;
}

.estimate-content-section.project_vs_left.small_box_review {
         margin-left: 0 !important;
}
}


@media only screen and (max-width: 1240px) and (min-width: 1025px) {
  .ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item .ref_window_name {
    min-width: 100px;
    max-width: 200px;
}
.right_banner_product a {
  width: 100%; 
  height: 120px; 
} 
 
.navbar-nav li a span { 
  font-size: 2.2rem !important;
  line-height: 3rem !important;
  margin-left: 10px !important;
}

}


@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item .ref_window_name {
    min-width: 80px;
    max-width: 200px;
}
.right_banner_product a {
  width: 100%;
  max-width: 370px;
  height: 130px;
  margin-left: auto !important;
}
.handle_banner_vs { 
  max-height: 75px; 
  min-height: 75px;
}
  .estimate-content-section.project_vs_left {
    max-width: 94% !important;
    margin-left: 0;
}
.videos_right_buttons {
  margin-left: -15px;
  padding: 5px;
}
.videos_right_buttons a {
  font-size: 10px;
}
.videos_right_buttons a img {
  width: 17px;
  height: 17px;
  margin-right: 3px;
}
  .handle_price_vs .form-control.qty_product {
    width: 100%;
    margin-top: 4px; 
    text-align: center;
  }
  .side-bar {
    max-width: 174px !important;
  }
  .estimate-content-section.project_vs_left {
    width: 100rem !important;
    max-width: 100% !important;
  }
  
  input#name {
    width: 100%;
    margin: 5px 0;
    padding: 1px 0 0 5px;
  }
  .handle_price_vs {
    display: block;
  }
  .handle_price_vs a {
    width: 100% !important;
  }
  .estimate-content-section.project_vs_left .right_schroll_vs p {
    text-align: center;
  }
  .Review_bottom_btn .review_project {
    width: 129px;
    height: 50px;
    font-size: 20px;
  }
  .owl-carousel.review-carousel .owl-item {
    width: 381px !important;
  }
}

@media (max-width: 991px) {
  .right_banner_product {
    max-width: 370px;
    margin: 10px auto;
  }
  .estimate-content-section.project_vs_left { 
    max-width: 100% !important; 
}
.project_slider_main .videos_right_buttons {
    margin: 15px auto;
    padding-bottom:10px ;
}
}
.handle_banner_vs img {
  width: auto;
  height: 100%;
  object-fit: fill;
  display: block;
  position: absolute;
  transform: translatex(-50%);
  left: 50%;
}
.handle_banner_vs {
  background-color: #fff;
}
.right_schroll_vs {
  margin-bottom: 30px;
}

.approved_button_right
  button.button-lottie-ani.border-0.bg-transparent.gray-lottie-btn.active
  div {
  display: none;
}

@media only screen and (max-width: 1298px) and (min-width: 1025px) {
  .handle_price_vs {
    display: block;
  }
  .handle_price_vs a {
    width: 100% !important;
  }
  .handle_price_vs .form-control.qty_product {
    width: 100%;
    margin-top: 6px;
  }
  .side-bar {
    max-width: 227px !important;
  }
  .estimate-content-section.project_vs_left {
    width: 135rem !important;
    max-width: 98% !important;
  }
}

.terms-page-content *,
.terms-page-content img,
.terms-page-content iframe * {
  max-width: 100% !important;
}

.show_hide_data a {
  display: none;
}

@media only screen and (max-width: 767px) {
  section.price-section-reviews .price-label h1, section.price-section-reviews h1 {
    white-space: nowrap;
    font-size: 22px !important;
    color: #fff;
    font-weight: 300;
}
section.price-section-reviews .price-label {
  padding-right: 3px;
}
.contact-no img.icon_review_print.img-fluid {
  filter: brightness(100%);
}
.contact-no  a {
 color: #fff !important;
}
  section.price-section-reviews .container .row {
    flex-direction: column-reverse;
    padding-top: 23px;
  }
  .col-lg-2.col-md-12.d-flex.justify-content-center.align-items-center.py-md-5.py-3 .approved_button_right {
    position: absolute;
    bottom: -100px;
}

  .owl-carousel.owl-theme.review-carousel .quote-confirm-sec {
    margin-top: 10px !important; 
}

/* .testvs {
    position:absolute;
    top:0px;
    left:0;
    text-align:center;
    padding:50px 0;
    background-color: yellow;
    width:100%;
    transform: translateY(100%);
    border:solid 1px black;
    transition: ease-in-out 5s;
} */

  .show_hide_data a {
    background: #34B2B0;
    border-radius: 0;
    text-decoration: none;
    width: 175px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

  .right_banner_product a {
    height: 220px;
    margin-left: unset;
    max-width: 370px;
    width: 100%;
    margin: auto !important;
} 
.estimate-content-section.project_vs_left .right_schroll_vs p {
  text-align: center;
}
.handle_option_product { 
  max-width: 370px;
  margin: auto;
  padding-top: 15px;
}
  .col-md-9.col-lg-10.payment-page.position-relative .main-content.align-items-start.py-5 {
    padding-bottom: 5rem !important;
}
  .p_progressname {
    width: 99%;
    margin: 15px auto;
    font-size: 14px;
    line-height: 22px;
}
.bg_prrogress .heading-section h1 {
  font-size: 5rem;
  line-height: 6.5rem;
}
  
  .header_team_vs.text-center {
    margin-top: -29px;
  }
  .header_team_vs h1,
  .header_team_vs p {
    color: #fff;
    margin-bottom: 0;
    line-height: 3.5rem;
  }
  .container-fluid.team-page.outer_team_pagesvs .header_team_vs.text-center,  .container-fluid.team-page.outer_team_pagesvs .bg_black_transparent_team {
    background: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background: transparent;
    margin-top: 0px;
    min-height: unset;
  
}

.container-fluid.team-page.outer_team_pagesvs .bg_black_transparent_team { 
  width:70%;
}

.container-fluid.team-page.outer_team_pagesvs .container .team-member {
  margin-top: 4rem;
  max-width: 80%;
  display: flex;
  width: 400px;
}

.container-fluid.team-page.outer_team_pagesvs .container .team-member img.profile-pic {
  width: 100px;
  height: 100px;
  min-width: 100px;
}


.container-fluid.team-page.outer_team_pagesvs .container {
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  background: #ffffff7a;
  padding-top: 20px;
  padding-bottom: 20px;
}
.container-fluid.team-page.outer_team_pagesvs .container ul.team-member-contacts li a {
  font-size: 13px;
  word-wrap: break-word;
  line-height: 13px;
}
.container-fluid.team-page.outer_team_pagesvs .container .team-member h3 {
  font-size: 20px;
  line-height: 18px;
}
.container-fluid.team-page.outer_team_pagesvs .container .header_team_vs h1, .header_team_vs p {
  color: #fff; 
}
  .header_team_vs h1   { 
    margin-bottom: 12px; 
  }
  .estimate-content-section.project_vs_left .estimate-left-sec.top_estimated_vs p {
     font-size: 13px; 
    line-height: 18px;
}
.estimate-left-sec.top_estimated_vs {
  width: 100%;
}
.estimate-right-sec.project_vs_right h3,.estimate-right-sec.project_vs_right p.top_price_schroll.bottom_total_project span, .estimate-right-sec.project_vs_right p.top_price_schroll.bottom_total_project {
 font-size: 16px; 
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item .ref_window_name {
  min-width: 90px;
  max-width: 90px;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5.last_items_total_price {
  min-width: 99px;
}
.project_main_content h5.dims_window_ref {
  width: 91px;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5.qty_window_ref {
  min-width: 40px;
}
.top_heading_schroll h4 {
  text-align: center;
}
.handle_banner_vs {
  max-height: 100px;
  min-height: 100px;
}
.term_sub_head_section p {
  font-size: 13px !important;
  line-height: 20px;
}
  .outer_make_form {
    min-height: auto;
  }

  .term_head_section h2 {
   font-size: 20px; 
}

.accept-sign-btn, .clear_pad_btn button.btn, .innr_section_pdf a:last-child { 
  font-size: 14px; 
}

p.top_price_schroll.bottom_total_project {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .check_disable_qty .form-control.qty_product {
    margin: 3px;
    font-size: 13px;
    margin-bottom: 0px;
  }

  .term_condition_sec p {
    font-size: 12px;
    margin-left: 11px;
    line-height: 17px;
}
  .main-content .container,
  .main-content .container-fluid,
  .footer-section .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .left_schroll_vs h6 span {
    margin-left: 10px; 
}
  .main-content .container,
  .footer-section .container {
    max-width: 100%;
  }

  a.navbar-brand {
    text-align: left;
    margin-bottom: 0;
    margin-left: 15px;
    padding-left: 0;
    padding-right: 0;
  }

  button.navbar-toggler {
    margin-right: 1.5rem;
  }

  .navbar-expand-md .navbar-collapse {
    background-color: #212529;
    padding: 1.5rem;
    border-top: 1px solid #262626;
    background-color: #181617;
  }

  .print-popup-overlay .modal-dialog,
  .info-popup-overlay .modal-dialog {
    max-width: 90% !important;
  }
  .request-form-overlay .modal-dialog {
    max-width: 90% !important;
  }

  .main-content .container {
    max-width: 100%;
    margin: auto;
  }

  .extra-btn-sec {
    position: unset;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    justify-content: space-between;
  }

  .pagination-sec {
    position: unset;
    width: calc(100% - 32rem);
    height: 11rem;
    padding: 1rem;
  }

  .pagination-sec ul.pagination-list {
    display: inline-flex;
  }

  .pagination-sec ul.pagination-list li {
    padding-bottom: 0 !important;
  }

  .estimate-content-section {
    width: 100%;
    margin-bottom: 3rem;
    height: 100%;
  }

  .ecs-scrolling-description-sec {
    height: auto;
  }

  .main-content {
    min-height: 100vh;
    padding-bottom: 17rem !important;
    padding-top: 17rem !important;
    height: 100%;
  }

  .side-bar {
    position: fixed;
    z-index: 1050;
    width: 100%;
  }

  .footer-section {
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 0.8);
  }

  .side-bar .navbar .chatbot-sec {
    display: none !important;
  }

  .chatbot-icon img {
    padding: 1.5rem;
  }

  .chatbot-icon svg {
    padding: 1rem;
  }

  .cta-btn .btn {
    padding: 2rem 2rem;
    border-radius: 1.5rem;
  }

  .cta-btn {
    background-color: transparent;
    backdrop-filter: unset;
  }

  .pagination-sec ul.pagination-list li a {
    width: 5rem;
    height: 5rem;
    font-size: 2.6rem;
  }

  .pagination-sec ul.pagination-list li + li {
    padding-left: 1rem;
  }

  .chatbot-icon {
    background-color: #181617;
  }

  .chatbot-icon g#Icon_ionic-ios-chatbubbles {
    fill: #fff;
  }
  .team-page .footer-section .cta-btn {
    justify-content: flex-end;
  }

  .terms-page-content {
    margin-bottom: 3rem;
    height: 100%;
  }

  .footer-section {
    position: fixed;
    z-index: 1020;
  }

  .print-info-btn {
    width: auto;
  }

  table.payment-stages tr th:first-child,
  table.payment-stages tr td:first-child {
    padding-right: 2rem;
  }

  .total-price-box {
    display: flex;
    width: 100%;
    margin-top: 3rem;
  }

  .total-price-box h3 {
    width: 50%;
  }

  .total-price-box h3 + h3 {
    border-left: 1px solid #afafaf;
    border-top: none;
  }

  /* .review-carousel.owl-theme .owl-nav {
        justify-content: center;
    } */

  .review-carousel.owl-theme .owl-nav > button .review-prv-img,
  .review-carousel.owl-theme .owl-nav > button .review-nxt-img {
    display: none !important;
  }

  .review-carousel.owl-theme .owl-nav .owl-dots .owl-dot {
    margin: 0;
  }

  .review-page .main-content {
    padding-bottom: 31rem !important;
  }

  .cta-btn,
  .popup-cta-btn,
  .pagination-sec {
    height: 9rem;
  }

  .pagination-scrollable {
    display: flex;
    align-items: center;
  }

  .popup-cta-btn img {
    width: 5rem;
  }

  .finished-overlay .modal-dialog,
  .request-form-overlay .modal-dialog {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
  }
  .total-estimate-sec h1 {
    font-size: 4.2rem;
    line-height: 4.4rem;
  }

  .print-popup-overlay .modal-dialog,
  .info-popup-overlay .modal-dialog,
  .payment-popup-overlay .modal-dialog {
    max-width: 90%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .request-form-overlay .quote-confirm-box {
    padding-right: 0 !important;
  }

  .popup-request-form {
    padding-left: 2rem;
    padding-bottom: 2rem;
  }

  .video-play-modal .modal-dialog {
    max-width: 90%;
  }

  .payment-popup-overlay .modal-dialog {
    max-width: 70%;
  }

  .ecs-scrolling-description-sec {
    margin-right: 0;
  }

  /* desable aos animation css */
  .aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .inactive.aos-init.aos-animate {
    -o-transition-property: inherit !important;
    -moz-transition-property: inherit !important;
    -ms-transition-property: inherit !important;
    -webkit-transition-property: inherit !important;
    transition-property: inherit !important;
    -o-transform: inherit !important;
    -moz-transform: inherit !important;
    -ms-transform: inherit !important;
    -webkit-transform: inherit !important;
    transform: inherit !important;
    -webkit-animation: inherit !important;
    -moz-animation: inherit !important;
    -o-animation: inherit !important;
    -ms-animation: inherit !important;
    animation: inherit !important;
  }

  .cust-height-100 {
    height: 100%;
  }

  .cust-height-100 .extra-btn-sec {
    height: auto;
    align-items: flex-end;
  }

  .dt_url {
    display: none;
  }
  
}

@media only screen and (max-width: 575px) {
  section.price-section-reviews .price-label h1, section.price-section-reviews h1 {
    white-space: nowrap;
    font-size: 22px !important;
    color: #fff;
    font-weight: 300;
}
section.price-section-reviews .price-label {
  padding-right: 3px;
}
.contact-no img.icon_review_print.img-fluid {
  filter: brightness(100%);
}
.contact-no  a {
 color: #fff !important;
}
  
.owl-carousel.owl-theme.review-carousel.aos-init.aos-animate.owl-loaded.owl-drag .owl-dots {
  display: none !important;
}
  .owl-carousel .owl-stage {
    transform: none !important;
    width: 100% !important;
}

  .videos_right_buttons a img {
    width: 27px;
    height: 27px;
    margin-right: 5px;
  }
  .videos_right_buttons .btn {
    margin-bottom: 7px;
  }
  .videos_right_buttons {
    margin-left: 0;
  }
  
  .outer_start_main p {
    font-size: 15px !important;
    line-height: 22px;
    max-width: 228px;
    margin: auto;
}
  .owl-carousel.review-carousel .owl-item {
    width: 100% !important;
    margin-right: 15px !important;
    position: unset !important;
  }
  .qc-des {
    padding: 1.5rem 1rem;
  }

  .print-popup-btn-sec a {
    font-size: 2rem;
    line-height: 3rem;
  }

  .print-popup-btn-sec a img {
    margin-left: 0.5rem;
    width: 3.5rem;
  }

  .info-btn-sec a img {
    width: 3.5rem;
  }

  .quote-image-sec {
    margin-right: 0;
    margin-bottom: 3rem;
  }
  .start-now-btn {
    margin-top: 0rem;
    margin-bottom: 4rem;
  }

  .modal-content p {
    margin-bottom: 2rem;
  }

  .modal-content h1 {
    margin-bottom: 2rem;
  }

  .contact-no,
  .cta-btn,
  .popup-cta-btn,
  .pagination-sec,
  .estimate-content-section {
    border-radius: 1.8rem;
  }

  ul.estimate-heading-list li {
    border-radius: 1.8rem 1.8rem 0 0;
  }
 

  .welcome-overlay .modal-dialog,
  .print-popup-overlay .modal-dialog,
  .info-popup-overlay .modal-dialog,
  .payment-popup-overlay .modal-dialog {
    top: 0;
    transform: none !important;
  }

  .active.aos-init.aos-animate {
    -o-transition-property: inherit !important;
    -moz-transition-property: inherit !important;
    -ms-transition-property: inherit !important;
    -webkit-transition-property: inherit !important;
    transition-property: inherit !important;
  }
  .col-md-12.col-lg-2.extra-btn-sec.main_button_reviw {
    flex-wrap: wrap;
}

.col-md-12.col-lg-2.extra-btn-sec.main_button_reviw .Review_bottom_btn.hide-btn {
    width: 100% !important;
    max-width: 100% !important;
    position: unset;
}

.col-md-12.col-lg-2.extra-btn-sec.main_button_reviw .Review_bottom_btn.hide-btn a {
    width: 100%;
}
}

@media only screen and (max-width: 479px) {
  .qc-button {
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -2rem;
  }
  .qc-button .btn {
    font-size: 2rem;
    padding: 0.5rem 1.5rem;
    height: 4.5rem;
    margin-left: 1rem;
  }

  .btn-delete {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
}

@media only screen and (max-width: 428px) {

  .ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item .ref_window_name {
    min-width: 95px;
    max-width: 95px;
}
.project_main_content h5.dims_window_ref {
  width: 90px;
}
.ecs-scrolling-description-sec .window_ref_sec .inlie_flex_item h5.qty_window_ref {
  min-width: 35px;
}
.estimate-right-sec {
  display: block; 
}
.estimate-right-sec.project_vs_right h3 {
  text-align: left;
}
  .chatbot-icon svg {
    padding: 1rem;
  }

  .vertical-carousel .slick-next {
    left: 5rem;
  }

  .heading-section h1 {
    font-size: 3.6rem;
    line-height: 3.8rem;
  }

  .info-btn-sec {
    padding-left: 0rem;
  }
  .pagination-sec {
    width: calc(100% - 25rem);
  }

  .glass-page .popup-cta-btn {
    width: 20rem !important;
  }

  .popup-cta-btn.print-info-btn img {
    width: 3rem !important;
  }

  form.payment-form {
    padding-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .second-popup button.btn-close {
    top: -15px;
  }

  /* safari-only css*/

  @media not all and (min-resolution: 0.001dpcm) {
    .button-lottie-ani > div {
      width: 80px !important;
    }
    .chatbot-icon {
      width: 50px;
      height: 50px;
    }
  }
}

/* custom css start */
.member-profile-cstm {
  display: flex;
  align-items: center;
}
.d-none-modals {
  display: flex !important;
}

.col-md-9.col-lg-10.payment-page.position-relative .heading-section h1 {
  color: #191816;
  font-weight: 400;
}

.innr_section_pdf{
  display: flex;
  flex-direction: column;
  justify-content: center;
}