.estimate-content-section.project_vs_left {
  width: 166rem !important;
  height: auto;
  padding-bottom: 60px;
  max-width: 1110px;
}
.estimate-content-section.project_vs_left .ecs-scrolling-description-sec {
  height: 100rem;
}
.estimate-content-section.project_vs_left {
  border-radius: 0 !important;
}

.estimate-content-section.project_vs_left
  .ecs-fixed-header
  .estimate-heading-list {
  border-radius: 0px !important;
}

.estimate-content-section.project_vs_left ul.estimate-heading-list li {
  border-radius: 0;
}
.estimate-content-section.project_vs_left
  ul.estimate-heading-list
  li:nth-child(odd) {
  background: #191816;
  padding-top: 3.7rem;
  padding-bottom: 3.7rem;
}
.estimate-content-section.project_vs_left .estimate-left-sec.top_estimated_vs h3 {
  color: #fff;
  font-size: 20px;
  line-height: 3.6rem;
  font-weight: 400;
  white-space: nowrap;
}

.estimate-left-sec.top_estimated_vs {
  width: 100%;
}

.estimate-content-section.project_vs_left .estimate-left-sec.top_estimated_vs p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
}
.estimate-right-sec.project_vs_right h3 {
  color: #fff;
  font-size: 20px;
  text-align: right;
  font-weight: 400;
}
.top_heading_schroll h4 {
  font-size: 3.6rem;
  line-height: 4rem;
  margin-bottom: 40px;
  margin-top: 23px;
  color: #191816;
}
.left_schroll_vs h6 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 400;
  color: #191816;
}
.left_schroll_vs h6 span {
  margin-left: 12rem;
  display: inline-block;
}

.estimate-content-section.project_vs_left .right_schroll_vs p {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 400;
  color: #191816;
  text-align: right;
  margin: 0px;
}
.left_detail_product h5 {
  font-size: 2.1rem !important;
  font-weight: 300 !important;
  color: #191816 !important;
}
.left_detail_product .top_left_list li {
  font-size: 2.4rem !important;
  font-weight: 200 !important;
  color: #191816 !important;
  line-height: 16px;
  margin-bottom: 13px !important;
}
.estimate-content-section.project_vs_left.collapsible-shutter {
  width: 70rem !important;
  height: 35rem !important;
}

.collapsible-shutter .top_price_schroll.bottom_total_project {
  display: none;
}
.estimate-content-section.project_vs_left.small_box_review {
  width: 80rem !important;
}
.right_banner_product {
  margin-top: 17px;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 4px;
}
.pdf_document_main {
  border: 1px solid #191816;
  padding: 10px 20px 20px 20px;
  height: 255px;
  margin-top: 20px;
}
.pdf_document_main.abg ul {
  margin: 0;
}

.pdf_document_main.abg ul li {
  padding-right: 20px;
}
.pdf_document_main.abg{
  border: 0;
  padding: 0;
  height: auto;
  margin-top: 0px;
}
.checkmark.active{
  background: #000;
}

.estimate-content-section.project_vs_left.collapsible-shutter .ecs-scrolling-description-sec h4, .estimate-content-section.project_vs_left.collapsible-shutter .ecs-scrolling-description-sec .row {
  display: none;
}
.estimate-content-section.project_vs_left.collapsible-shutter .ecs-scrolling-description-sec h3 {
  display: block;
  text-align: center;
  font-size: 28px;
  padding-top: 40px;
  font-weight: 300;
  color: #191816;
}
.estimate-content-section.project_vs_left.collapsible-shutter .shutter-sec {
  background: transparent !important;
  padding: 0 !important;
  transition: 0.3s ease-in-out;
}
.top_heading_schroll h3{
  display: none;
}

.estimate-content-section.project_vs_left.collapsible-shutter .shutter-sec P {
  display: none;
}
.extra_enhanacments_product p {
  font-size: 14px !important;
  /* font-style: italic; */
  color: #191816;
}
.extra_enhanacments_product h5 {
  font-size: 16px !important;
  color: #191816;
}
.pdf_document_main ul.pdf_schroll_slider {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0 50px 0;
  margin: 40px 0 0px 0;
  list-style: none;
}
.specification_products_bottom h5 {
  font-size: 2.5rem !important;
  color: #191816 !important;
  font-weight: 400;
}
.specification_products_bottom ul.bottom_left_list li {
  font-size: 2.3rem !important;
  color: #191816 !important;
}
.pdf_document_main ul.pdf_schroll_slider li a {
  background: transparent;
  color: #191816;
  font-size: 11px;
  font-weight: 400;
  margin: 0;
    margin-bottom: 0px;
  padding: 0 12px 0 0;
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}

.pdf_document_main h3 {
  text-align: center;
}

.handle_option_product {
  border: 1px solid #191816;
  padding: 10px 15px 15px 15px;
  margin-top: 20px;
}

.handle_option_product h3 {
  text-align: center;
  margin-bottom: 15px;
}
.inner_handle_product {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto; 
  padding-right: 11px;
  padding-top: 12px;
}
.handle_price_vs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listing_product_handle {
  width: 47%;
  margin-bottom: 12px;
}

.handle_price_vs a {
  font-size: 11px;
  border-radius: 0;
  display: block;
  width: 66px;
  height: 20px;
  line-height: 12px;
  text-align: center;
  padding: 3px 0;
  text-decoration: none;
  font-weight: 400;
}

.handle_price_vs p {
  font-size: 14px !important;
  margin: 0;
}

.handle_banner_vs {
  border: 1px solid #191816;
  margin-bottom: 9px;
  max-height: 90px;
  object-fit: fill;
  overflow: hidden;
  position: relative;
  min-height: 90px;
}
.handle_banner_vs .colapsible-icon {
  background: rgb(0 0 0 / 60%);
  right: inherit;
  bottom: inherit;
  width: 89px;
  height: 64px;
  transform: rotate(137deg);
  top: -28px;
  left: -34px;
}

.handle_banner_vs .colapsible-icon p {
  color: #fff !important;
  transform: rotate(183deg);
  font-size: 11px !important;
  position: absolute;
  top: 5px;
  left: 29px;
  padding: 0 !important;
  line-height: 20px !important;
}
.handle_price_vs .form-control.qty_product {
  background: #fff;
  border-radius: 0px;
  height: 20px;
  width: 47px; 
  border: 1px solid #191816;
  font-size:12px ;
}
.handle_price_vs .select_handle_btn {
  background: #fff;
  color: #191816;
  border: 1px solid #191816;
}
.handle_price_vs a {
  font-size: 11px;
  border-radius: 0 !important;
  display: block;
  width: 66px !important;
  height: 20px !important;
  line-height: 12px;
  text-align: center;
  padding: 3px 0 !important;
  text-decoration: none;
  font-weight: 400;
}
.handle_price_vs .selected_handle_btn {
  background: #191816;
  font-weight: 400;
  border: 1px solid #191816;
}
 
.checkbox_price_outer .checkbox {
  position: relative !important;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  align-items: flex-end;
}
.checkbox_price_outer .checkbox label { 
  letter-spacing: 0px;
}
.lable_flex2 h6 {
  margin-left: 5px;
}
@keyframes pull {
  0% {
    height: 0;
  }
  100% {
    height: 1.2rem;
  }
}
@keyframes pop {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
  }
}
.checkbox-flip, .checkbox-pop, .checkbox-pull, .checkbox-spin {
  display: none;
}

.checkbox-flip + label span,
.checkbox-pop + label span,
.checkbox-pull + label span,
.checkbox-spin + label span {
  display: inline-block;
  width: 25px;
  height: 19px;
  margin: 0 5px -4px 0;
  /*layout relationship between check and label*/
}
.checkbox-flip + label span:before, .checkbox-flip + label span:after,
.checkbox-pop + label span:before,
.checkbox-pop + label span:after,
.checkbox-pull + label span:before,
.checkbox-pull + label span:after,
.checkbox-spin + label span:before,
.checkbox-spin + label span:after {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: 1px solid #191816;
}
.checkbox-flip + label span:after,
.checkbox-pop + label span:after,
.checkbox-pull + label span:after,
.checkbox-spin + label span:after {
  z-index: 0;
  border: none;
}

.checkbox-flip:checked + label span:before {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: #191816;
}

.checkbox-pop:checked + label span:before {
  animation: pop 0.3s ease;
  z-index: 100;
  background: #191816;
}

.checkbox-pull:checked + label span:after {
  width: 3rem;
  height: 3rem;
  background: #191816;
  animation: pull 0.4s linear;
}

.checkbox-spin:checked + label span:before {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  background: #191816;
}

.check_disable_qty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

input#name {
  background: transparent;
  color: #191816 !important;
  width: 50px;
  height: 22px;
  border-radius: 0;
  margin: 0 10px;
  display: flex;
  padding: 1px 0 0 8px;
  font-size: 11px !important;
  border: 1px solid #191816 !important;
}

input#name::placeholder {
  color: #191816;
  font-size: 11px;
}

p.disable_btn_first {
  background: transparent;
  color: #191816 !important;
  border-radius: 0;
  padding: 3px 3px;
  height: 22px;
  font-size: 11px;
  margin: 0;
  border: 1px solid #191816;
}
div#welcome-modal {
  display: none !important;
}
.estimate-content-section p.last_total_cost {
  min-width: 110px;
}
 

li.checkbox.active_buttons p.disable_btn_first {
  background: transparent;
  color: #191816 !important;
}
li.checkbox.active_buttons input#name {
    color: #191816 !important;
    background: transparent;
}

li.checkbox.active_buttons input#name::placeholder {
    color: #fff;
}
.Notes_to_customer h5 { 
   font-size: 16px !important;
   color: #000;
   line-height: 2.4rem;
   font-family: proxima-nova, sans-serif;
   font-weight: 500;
   margin-bottom: 1.5rem;
}
.Notes_to_customer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.videos_right_buttons a:active {
  color: #191816 !important;
} 
.videos_right_buttons a {
  padding: 0;
  margin: 0 0 10px 0;
  border: 0;
  color: #191816 !important;
  font-size: 14px;
  white-space: nowrap;
}
.videos_right_buttons {
    margin-top: 0;
    margin-left: 0px;
    /* background: rgb(255 255 255 / 80%); */
    -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background: #ffffff7a;
    padding: 10px;
    padding-bottom: 0; 
}
.videos_right_buttons a img {
  width: 30px;
  filter: brightness(0);
}
.estimate-content-section.project_vs_left .shutter-sec {
   border-radius: 0;
}
.estimate-content-section.project_vs_left .shutter-sec .colapsible-icon img {
  transition: 0.3s ease-in-out;
  width: auto;
  transform: rotate(311deg);
}
.estimate-content-section.project_vs_left .shutter-sec .colapsible-icon {
  background: #191816;  
}
.Review_bottom_btn .review_project {
  background: #9BD4D3;
  color: #fff;
  text-decoration: none;
  width: 139px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  font-size: 28px;
  font-weight: 500 !important;
}
.main_button_reviw{
  position: relative !important;
  height: auto !important
}
.Review_bottom_btn {
  position: absolute;
  bottom: 0;
  right: 0px;
}
a.modal_product_video {
  background: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}
.body_product_box h5 {
  display: block;
  text-align: center;
  font-size: 28px;
  padding-top: 40px;
  font-weight: 300;
  color: #191816;
}
.estimate-content-section.project_vs_left.small_box_review {
  max-width: 437px;
}
.owl-carousel.review-carousel .owl-item {
  width: 559px !important;
}

.handle_price_vs a.active{
  background-color:#62BBBC;
  color: #fff;
}
.inlie_flex_item{
  flex-direction: column;
}
.inlie_flex_inner_item {
  display: flex;
  justify-content: space-between;
}

 
@media (max-width: 1600px) { 
 
  input#name { 
    width: 45px; 
    margin: 0 5px; 
    padding: 1px 0 0 5px; 
}
.estimate-content-section p.last_total_cost {
  min-width: 103px;
}
li.checkbox.active_buttons p.disable_btn_first { 
   padding: 2px 2px;
}
  p.disable_btn_first { 
  padding: 2px 2px;
}
.checkbox-flip + label span, .checkbox-pop + label span, .checkbox-pull + label span, .checkbox-spin + label span {
  width: 23px;
  height: 19px;
  margin: 0 -3px -4px 0;
}
.Review_bottom_btn a {
  width: 130px;
  height: 50px; 
  font-size: 22px;   
}
.top_heading_schroll h4 {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 3rem;
  margin-top: 1rem; 
}

  .videos_right_buttons {
    margin-left: -6px;
  }
.handle_price_vs p {
  font-size: 13px !important;
  margin: 0;
}
.handle_price_vs a {
  font-size: 11px; 
  width: 54px !important;
  height: 20px !important;
  line-height: 12px; 
}
.inner_handle_product {
  padding-right: 5px;
}
.videos_right_buttons a {
   font-size: 13px; 
}
.handle_option_product {  
  padding: 5px;  
}
  .estimate-content-section.project_vs_left {
      width: 135rem !important;
      height: auto;
      padding-bottom: 40px;
  }
  .side-bar {   
    max-width: 268px !important;
}
.navbar-nav li a span {  
  margin-top: 1rem !important;
  font-size: 2.7rem !important;
  line-height: 3.1rem !important;
  margin-left: 13px !important;
}
.main-content .container, .footer-section .container {
  max-width: 95% !important;
}
}

@media (max-width: 1440px) { 
  .side-bar {
    max-width: 244px !important;
  }
  .estimate-content-section.project_vs_left {
    width: 135rem !important;
    height: auto;
    padding-bottom: 40px;
    max-width: 100%;
}
.extra_enhanacments_product p {
  font-size: 11px !important;
}

  }

  @media (max-width:1024px) { 
    .side-bar {
      max-width: 250px !important;
  }
  

  .videos_right_buttons .btn {
    width: 100%;
    display: flex;
}
.videos_right_buttons {
  margin-top: 0px;
  
}
.estimate-content-section.project_vs_left.small_box_review {
  max-width: 100%;
}
.body_product_box h5 {
  padding-top: 32px; 
  padding-bottom: 37px;
}

}

@media (max-width: 991px) { 
  .videos_right_buttons a img {
    width: 30px;
    filter: none !important;
  }

  .videos_right_buttons a {
     color:#fff !important;
     font-size:16px;
  }

  .videos_right_buttons a img { 
  margin-right:12px !important;
  }

.Review_bottom_btn {
  position: absolute;
  bottom: -65px;
  right: 0px;
}
.videos_right_buttons { 
  width: 100%;
}
}

  @media (max-width: 767px) { 
  .side-bar {
    max-width: 100% !important;
}
.estimate-content-section.project_vs_left {
  width: auto !important;
  padding-bottom: 0;
} 
.inner_handle_product .listing_product_handle {
  width: calc(33% - 6px) !important;
  margin-right: 6px !important;
}

.inner_handle_product .handle_price_vs.disable_qtya {
    flex-wrap: wrap !important;
    flex-direction: column;
    width: 100% !important;
}

.inner_handle_product .handle_price_vs.disable_qtya a {
    width: 100% !important;
    /* background: #34B2B0 !important;
    color: #fff !important;
    border-color:#34B2B0 !important; */
}
.inner_handle_product {
  padding-right: 0px !important ;
  justify-content: flex-start !important;
}
.inner_handle_product .handle_price_vs.disable_qtya input {
    margin-top: 7px !important;
    text-align: center;
    width: 100%;
}
input#name {
  width: 100%;
  margin: 5px;
  padding: 5px;
}
li.checkbox.active_buttons p.disable_btn_first {
  padding: 2px 2px;
  width: 100%;
}
 
.estimate-content-section p.last_total_cost {
   width: 100%;
}
  }