table.payment-stages tr.active td {
  font-weight: 600;
  opacity: 1;
}
h2.payment_lable_top span img {
  margin-right: 10px;
}
h2.payment_lable_top {
  text-align: center;
  margin-bottom: 15px;
  font-size: 35px;
  color: #191816;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info_payment_glazing p {
  text-align: center; 
  font-size: 21px;
  color: #191816; 
  font-weight: 300;
}
.yg_number_txt {
  padding-bottom:40px;
  margin-bottom:0px;
}

.d-flex.acount_flex_text {
  justify-content: space-between;
  max-width: 470px;
  margin: auto;
  padding-bottom: 50px;
}
.d-flex.icons_gpay img { 
  margin-right: 9px;
}
.d-flex.icons_gpay .visacardvs {
  height: auto;
  max-height: none;
  max-width: none;
  width: 29px;
  height: 21px;
}

img.mastercard_visa {
  height: auto;
  max-height: none;
  max-width: none;
  width: 29px;
  height: 21px;
}

img.applepayment {
  max-height: none;
  max-width: none;
  width: 31px;
  height: 17px;
}

img.gpaypaymentvs {
  width: 31px;
  height: 13px;
}
.d-flex.bottom_payment_gpay p {
  margin: 0;
  margin-right: 12px;
  color: #191816;
  font-size: 15px;
  font-weight: 300;
}
.d-flex.icons_gpay {
  align-items: center;
}


@media (max-width: 1024px) {

.info_payment_glazing p { 
  font-size: 15px; 
}
.yg_number_txt {
  padding-bottom: 20px; 
}
.d-flex.acount_flex_text { 
  padding-bottom: 20px;
}
h2.payment_lable_top { 
  margin-bottom: 15px;
  font-size: 25px; 
}
h2.payment_lable_top span img {
  margin-right: 4px;
  max-width: 29px;
  height: auto;
}
.d-flex.bottom_payment_gpay p { 
  margin-right: 0px; 
  font-size: 13px; 
}
.d-flex.icons_gpay img {
  margin-left: 5px;
  margin-right: 0;
}
}

@media (max-width: 767px) {

.d-flex.bottom_payment_gpay {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.d-flex.bottom_payment_gpay p { 
  margin-right: 0; 
  font-size: 14px; 
  margin-bottom: 6px;
}
.d-flex.acount_flex_text {
   max-width: 320px; 
}
}